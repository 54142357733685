import axios from "axios";
import { getValidationError } from "../utilities/get-validation-error.utililty";

const { REACT_APP_API_URL } = process.env;

axios.defaults.baseURL = REACT_APP_API_URL;

export const AxiosInterceptor = () => {
  const updateHeader = (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      const newHeaders = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      };
      request.headers = newHeaders;
    }
    return request;
  };

  axios.interceptors.request.use((request) => {
    return updateHeader(request);
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error", error);
      console.log(getValidationError(error.code) + " " + JSON.stringify(error.response.data));
      return error;
    }
  );
};

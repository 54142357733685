import { Navigate, useLocation } from "react-router-dom";
import { getPropertieRefFromIdInmovilla } from "../service";
import { useState, useEffect } from 'react';

const RedireccionInmovilla = (props) => {
    const location = useLocation();
    const queyParams = new URLSearchParams(location.search);
    const codigo = queyParams.get('codigo');
    const [ruta, setRuta] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!codigo) setRuta('/');
            const [, idPropiedadInmovilla,] = codigo.split("_")
            if (!idPropiedadInmovilla) setRuta('/');
            const {referencia, conservacion} = await getPropertieRefFromIdInmovilla(idPropiedadInmovilla)
            if (referencia === undefined) setRuta('/');
            const ruta = conservacion === "Obra Nueva" ? '/obra-nueva/' : '/propiedades/';
            setRuta(ruta + referencia);
        };
        fetchData();
    }, [codigo]);

    return ruta ? <Navigate to={ruta} replace/> : null;
}
 
export default RedireccionInmovilla;
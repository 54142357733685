import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import global_es from "./es/global_es.json";
import global_en from "./en/global_en.json";
import global_fr from "./fr/global_fr.json";

// Configuración de idiomas
const resources = {
  es: {
    global: global_es
  },
  en: {
    global: global_en
  },
  fr: {
    global: global_fr
  },
};

i18n
  .use(initReactI18next)
  .init({
    lngs: "es",
    fallbackLng: "es",
    
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18n;
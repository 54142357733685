import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
const ResultadoObraNueva = ( {datosFiltrados} ) => {
    const { t, i18n } = useTranslation("global");
    const [ numIdioma, setNumIdioma ] = useState(0);
    const location = useLocation();
    let idioma = i18n.language;

    useEffect(()=>{
        let numI = 0
        switch (idioma) {
            case 'es':
                numI=1;
            break;
            case 'en':
                numI=2;
            break;
            case 'fr':
                numI=4;
            break;
            default:
                numI=1;
        }
        setNumIdioma(numI);
    },[]);

    return ( 
    <>
        <div className="container-fluid pt-5">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                <div className="row">
                    {datosFiltrados.length ===0 ?
                         <div className='text-center my-5 display-6' style={{ fontWeight: 'lighter' }}>{t("nopropiedades")}</div>
                        :
                        datosFiltrados.map((p,i)=>{
                            console.log();
                            return(
                                    <div key={i} className='col-lg-4 col-md-6'>
                                        <Link to={`/obra-nueva/${p?.referencia}`} target='_blanck'>
                                            <div className="obra-card">
                                                <div className='back-color'></div>
                                                <div className="content">
                                                    <h5 className="title">{p?.textos.filter(t => t.id_idioma === numIdioma)[0]?.titulo}</h5>
                                                    <p className="my-p">{p?.provincia}</p>
                                                </div>
                                                <img className='imagen' src={p?.foto?.url} alt={`${p?.textos.filter(t => t.id_idioma === numIdioma)[0]?.titulo}`} />
                                            </div> 
                                        </Link>
                                    </div>
                               
                            )
                        })
                    }
                </div>   
            </div> 
        </div>
        <style jsx="true">{`
            .obra-card {
                height: 400px;
                overflow: hidden;
                position: relative;
                font-family: Arial, Helvetica, sans-serif;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 300ms;
                text-align: center;
                display: flex !important; 
                align-items: center !important; 
                justify-content: center !important; 
            }
            .row>* {
                padding-right: inherit;
                padding-left: inherit;
               
            }
            .obra-card:hover {
                transform: translateY(-2px);
                box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            }
            .back-color:hover {
                background-color: rgba(0, 0, 0, 0.0) !important;
            }
            .obra-card .content:hover
            {
                background-color: rgba(0, 0, 0, 0.0)!important;
            }
            .obra-card img {
                width: 100%;
                position: absolute;
                height: 100%;
                object-fit: cover;
                /* padding-right: 10px; */
                z-index: -1;
            }
            .obra-card .back-color {
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2);
            }
            .obra-card .content {
                box-sizing: border-box;
                width: 100%;
                position: absolute;
                padding: 150px 20px 20px 20px;
                height: 100%;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.2);
                
            }
            // .obra-card .title{
            //     line-height: 300px;
            // }
            // .obra-card .my-p{
            //     line-height: -100px;
            // }
            .obra-card .my-p,
            .obra-card .title {
                margin: 0;
                
            }
          
            .obra-card .my-p {
                font-size: 20px;
                color: rgba(255, 255, 255, 0.9);
                margin-bottom: 4px;
            }
          
            .obra-card .title {
                font-size: 30px;
                color: #fff;
                
            }
          
         
        `}</style>
    </>
    
    );
    
}

export default ResultadoObraNueva;
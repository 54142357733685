import Footer from "../static/footer";
import Menu from "../static/menu";
import Img from "./../images/TextosLegales/Banner-superior-textos-legales.jpg"
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import SEO from "../utilities/SEO";

const PoliticaPrivacidad = () => {
    const { t } = useTranslation("global");
    return ( 
        <>
            {SEO({title: "Política de privacidad", type: 'website', ruta: "/politica-privacidad"})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Política de privacidad</h1>
                    </div>

                </div>
            </div>
            <Linea1 />
            <div className="container-xxl pd-5 pdb-5">
                <div className="container">
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">POLÍTICA DE PRIVACIDAD DEL SITIO WEB</h4>
                        <p>
                            Somos una empresa en la que la transparencia en la información es fundamental para establecer una relación de confianza con las personas que forman nuestro proyecto y con nuestros proveedores y clientes, siendo la protección de los datos personales de las personas que confían en nosotros de vital importancia, por lo que queremos mediante la presente Política de privacidad informarte de cómo recabamos y cómo tratamos o utilizamos dichos datos.
                        </p>
                        <p> 
                            En esta política de privacidad se establecen las bases sobre las que la empresa, trata los datos de carácter personal que voluntariamente nos facilitan nuestros usuarios a través de la página web.
                        </p>
                        <p>
                            Esta Política siempre será aplicable cuando se faciliten datos a la empresa, el cual será responsable de sus datos (Responsable del Tratamiento de los Datos), y a través de cualquiera de los formularios de contacto o suscripción donde se recojan datos de carácter personal (nombre, email, etc.).
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">A QUÉ NOS COMPROMETEMOS</h4>
                        <ul>
                            <li>A respetar la privacidad de nuestros usuarios y tratar los datos personales con el consentimiento de los mismos.</li>
                            <li>A tratar los datos personales que estrictamente necesitemos para dar el servicio solicitado, y tratarlos de forma segura y confidencial, tomando las medidas necesarias para evitar el acceso no autorizado y el uso indebido de los mismos.</li>
                            <li>A no utilizar los datos personales para finalidades para las que no hemos obtenido previamente el consentimiento.</li>
                            <li>A no dar los datos personales a terceros ni compartirlos.</li>
                            <li>A conservar los datos personales durante el tiempo estrictamente necesario para la finalidad concreta para la que han sido proporcionados.</li>
                        </ul>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUIÉN ES EL RESPONSABLE DE TUS DATOS PERSONALES</h4>
                        
                        <table className="table text-center ">
                            <tbody>
                                <tr>
                                    <td>Nombre de la empresa</td>
                                    <td>Lae Consulting Normativas, S.L.</td>
                                </tr>
                                <tr>
                                    <td>NIF</td>
                                    <td>B75010686</td>
                                </tr>
                                <tr>
                                    <td>Dirección</td>
                                    <td>C/ Juan F. Gilisagasti, 2, 20018, San Sebastián, Guipúzcoa</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUÉ ES UN DATO PERSONAL Y EL TRATAMIENTO DE DATOS PERSONALES</h4>
                        <p>Un dato personal es toda información sobre una persona física identificada o identificable. Se considera persona física identificable toda persona cuya identidad pueda determinarse directa o indirectamente, en particular mediante un identificador, como por ejemplo un nombre, un número de identificación, datos de localización, un identificador en línea o uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona. </p>
                        <p>Asimismo, el tratamiento de datos personales implica cualquier operación o conjunto de operaciones realizadas sobre datos personales o conjuntos de datos personales, ya sea por procedimientos automatizados o no, como la recogida, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, utilización, comunicación por transmisión, difusión o cualquier otra forma de habilitación de acceso, cotejo o interconexión, limitación, supresión o destrucción.</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUÉ TRATAMIENTOS REALIZAMOS SOBRE SUS DATOS PERSONALES Y PARA QUÉ FINALIDADES</h4>
                        <ul>
                            <li>
                                <p className="mb-0">Solicitud de información por parte del usuario</p>
                                <p>Gestionar la solicitud de información remitida por parte del usuario a través del formulario de contacto y/o la dirección de correo electrónico, correo postal y/o número de teléfono facilitado a través del sitio web.</p>
                            </li>
                                <p className="mb-0">Suscripción a newsletter</p>
                                <p>Gestionar la suscripción al envío de newsletter.</p>
                            <li>
                                <p className="mb-0">Trabajar con nosotros mediante el envío del currículum</p>
                                <p>Gestión de los CV recibidos a través del formulario disponible en el sitio web.</p>
                            </li>
                            <li>
                                <p className="mb-0">Alta y acceso al perfil de usuario</p>
                                <p>Gestión del alta y del acceso al portal de usuario mediante la correspondiente cuenta de usuario y contraseña.</p>
                            </li>
                            <li>
                                <p className="mb-0">Gestión de los comentarios en el blog.</p>
                                <p>Gestionar el envío y recepción de los comentarios que el usuario haya dejado en el blog.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE LOS DATOS PERSONALES</h4>
                        <p>De acuerdo con la normativa vigente en materia de protección de datos, necesitamos una base de legitimación para el tratamiento de sus datos personales. La base de legitimación es su consentimiento.</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUÉ DERECHOS TIENE SOBRE LOS DATOS PERSONALES APORTADOS</h4>
                        <p>Como usuario, puede ejercitar los siguientes derechos: </p>
                        <ul>
                            <li>
                                <u>Derecho de acceso: </u>Tiene derecho a obtener por parte de la empresa confirmación de si se están tratando o no sus datos personales que, y en su caso, qué datos trata.
                            </li>
                                <u>Derecho de rectificación: </u>Tiene derecho a obtener por parte de Lae Consulting Normativas, S.L., la rectificación de los datos personales inexactos que le conciernan. En la solicitud de rectificación deberá indicar los datos que desea que se modifiquen.
                            <li>
                                <u>Derecho de oposición: </u>Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, a que datos personales que le conciernan sean objeto de un tratamiento basado en el interés legítimo de la empresa. En ese caso, la empresa dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento  que prevalezcan sobre sus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.
                            </li>
                            <li>
                                <u>Derecho de supresión: </u>Tiene derecho a obtener por parte de Lae Consulting Normativas, S.L., la supresión de los datos personales que le conciernan.
                            </li>
                            <li>
                                <u>Derecho a la portabilidad de los datos: </u>Tiene derecho a que la empresa transfiera sus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en su consentimiento y el tratamiento se realice por medios automatizados.
                            </li>
                            <li>
                                <u>Derecho a la limitación del tratamiento: </u>Tiene derecho a solicitarnos que suspendamos el tratamiento de sus datos cuando: impugne la exactitud de los datos, mientras Lae Consulting Normativas, S.L. verifica dicha exactitud; haya ejercido el derecho de oposición al tratamiento de sus datos, mientras que se verifica si los motivos legítimos de Lae Consulting Normativas, S.L. prevalecen sobre los suyos como interesado.
                            </li>
                        </ul>
                        <p>En cualquier momento, podrá ejercitar estos Derechos, mediante un escrito y acreditando su personalidad, dirigido al responsable del tratamiento a la dirección señalada anteriormente, o a través de la dirección de correo electrónico: info@laehomes.com.</p>
                        <p>Además, podrá presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si considera que el tratamiento de datos personales infringe la legislación aplicable.</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUÉ TRATAMIENTOS REALIZAMOS SOBRE SUS DATOS PERSONALES Y PARA QUÉ FINALIDADES</h4>
                        <p>A través de nuestra página web podemos recabar datos personales para diferentes finalidades:</p>
                        <ul>
                            <li>
                                <p className="mb-0">Solicitud de información por parte del usuario</p>
                                <p>Captaremos aquellos datos personales que nos haya facilitado con motivo de la consulta planteada.</p>
                            </li>
                                <p className="mb-0">Suscripción a newsletter</p>
                                <p>Captaremos su dirección de correo electrónico.</p>
                            <li>
                                <p className="mb-0">Trabajar con nosotros mediante el envío del currículum</p>
                                <p>Captaremos la información contenida en el CV que nos haya facilitado.</p>
                            </li>
                            <li>
                                <p className="mb-0">Alta y acceso al perfil de usuario</p>
                                <p>Nombre de usuario y contraseña, así como cualquier otro dato que se haya facilitado con el fin de crear la cuenta o perfil.</p>
                            </li>
                            <li>
                                <p className="mb-0">Gestión de los comentarios en el blog.</p>
                                <p>Cualquier dato que se haya facilitado al publicar el correspondiente comentario. </p>
                            </li>
                        </ul>
                        <p>Dependiendo de la finalidad para la que se aportan los datos, serán requeridos los datos estrictamente necesarios.</p>
                        <p>Es importante que el usuario sea consciente de los datos que aporta y la finalidad para la que lo hace porque en el momento que los facilita, está aceptando que recopilemos, almacenemos y usemos dichos datos para la finalidad requerida, pudiendo en cualquier momento retirar el consentimiento prestado por los cauces oportunos siendo el consentimiento del interesado la base legal y legitimación para el tratamiento de sus datos personales.</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">SEGURIDAD Y ALMACENAMIENTO DE LOS DATOS</h4>
                        <p>Mantendremos los datos durante el tiempo estrictamente necesario para la prestación del servicio solicitado y mientras el titular no solicite su eliminación, adoptando las medidas adecuadas de carácter técnico y organizativo contra el tratamiento no autorizado o ilícito y contra su pérdida, destrucción o daño accidentales, haciendo todo lo posible por protegerlos y mantenerlos de forma segura.</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">QUÉ DATOS PERSONALES COMPARTIMOS CON TERCEROS</h4>
                        <p>No se prevén cesiones de datos salvo en aquellos casos en que exista una obligación legal o comunicación necesaria para poder prestarle el servicio solicitado adecuadamente. No hay previsión de transferencias de datos internacionales.</p>
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }                
            `}</style>
        </>
     );
}
 
export default PoliticaPrivacidad;
import React, { useState, useEffect, useRef } from "react";
import Footer from "../static/footer";
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import bgImg from '../images/Contacto/Banner-superior-contacto.jpg';
import Ciudad from '../images/Contacto/img-marbella.jpg';
import axios from 'axios';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PoliticaContacto from "../static/modalPoliticaContacto";
import PhoneInput from 'react-phone-input-2';
import useGeoLocation from "react-ipgeolocation";
import { useTranslation } from "react-i18next";
import { FaXTwitter } from "react-icons/fa6";
import SEO from "../utilities/SEO";

const { REACT_APP_API_URL } = process.env;
const Contacto = () => {
    const { t, i18n } = useTranslation("global");
    let loc_country = useGeoLocation().country;
    loc_country = loc_country === undefined ? '': loc_country.toLowerCase();

    const [show, setShow] = useState(false);
    const [ datos, setDatos ] = useState({})
    const inputRef = useRef(null);

    const showPhone = (val,coun,ev,form) =>{
        
        const telefono = form
        setDatos({
            ...datos,
            [ev.target.name]: telefono
        });
    }

    const handleChange = (evt) => {
        let value = ''
        if(evt.target.name=== 'terms' || evt.target.name==='suscribir'){
            value = evt.target.checked
        }else{
            value = evt.target.value
        }
        setDatos({
          ...datos,
          [evt.target.name]: value
        });

    }
    
    useEffect(() => {   
        /* if (inputRef.current) {
            console.log(inputRef.current.scrollToTop())
            //inputRef.current.blur();
        } */
        ReactRecaptcha3.init('6LeaJ_4kAAAAAOur2P6Q1eN3cakeY8_Hyra2wnxk').then(status => {
            console.log(status)
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        //console.log(datos)
        ReactRecaptcha3.getToken().then(async token => {
            const toastInfo = toast.info("Tus datos se están enviando",{autoClose:false})
            //console.log(token)
            datos.token = token
            // console.log(datos)
            const response = await axios.post(`${REACT_APP_API_URL}/sendEmail/enviarDatos`, datos)
            .then(res=>{
                // console.log(res)
                if(res.status >= 200 && res.status < 300){
                    if(res.data.message === 'EMAIL_SENDED'){
                        event.target.reset();
                        document.getElementById('telefono').value = '';
                        //quitarClaseTel()
                        //toast.success("Correo enviado correctamente")
                        toast.update(toastInfo, {
                            render: "Correo enviado correctamente",
                            type: toast.TYPE.SUCCESS,
                            autoClose: true
                        });
                        return res;
                    }else{
                        //toast.error("No se ha podido enviar el email");
                        toast.update(toastInfo, {
                            render: "No se ha podido enviar el email",
                            type: toast.TYPE.ERROR,
                            autoClose: true
                        });
                        console.log("No se ha podido enviar el email")
                    }
                }else{
                    //toast.error("Ha ocurrido un error inesperado")
                    toast.update(toastInfo, {
                        render: "Ha ocurrido un error inesperado",
                        type: toast.TYPE.ERROR,
                        autoClose: true
                    });
                }
                return res;
            }).catch(err => {
                console.log(err.response);
                toast.error("Ha ocurrido un error inesperado")
            });
            
            
        }, error => {
        // handle error here
        console.log(error)      
        })
    }

    return ( 
        <>
            {SEO({title: t("contacto.contactUs"), type: 'website', ruta: "/contacto"})}
            <Menu />
            
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">{t("contacto.contactUs")}</h1>
                    </div>
                </div>
            </div>
            <Linea1 />

            <div className="container-xxl py-5 contact-info">
                <div className="container">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div>
                                <h2 className="mb-4">{t("contacto.informacionGeneral")}</h2>
                                <div className="d-flex pb-3 mb-3">
                                    <div className="ms-3">
                                        <h6>{t("contacto.oficinaCentral")}</h6>
                                        <p className="fs-6 m-0">Juan F. Gilisagasti Kalea, 2 - Of. 215</p>
                                        <p className="fs-6 m-0">20018 Donostia, Gipuzkoa</p>
                                    </div>
                                </div>
                                <div className="d-flex pb-3 mb-3">
                                    <div className="ms-3">
                                        <h6>{t("contacto.telefono").toUpperCase()}</h6>
                                        <p className="fs-6 m-0">+34 943 058 955</p>
                                    </div>
                                </div>
                                <div className="d-flex-0 pb-3 mb-3">
                                    <div className="ms-3">
                                        <h6>{t("contacto.email").toUpperCase()}</h6>
                                        <p className="fs-6 m-0">info@laehomes.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5">
                                <h2 className="mb-4">{t("contacto.siguenos")}</h2>
                                <div className="d-flex border-bottom-0 pb-3 mb-3">
                                    <div className="ms-3 d-flex">
                                        <a className="btn btn-sm-square color-white bg-dark icon-contact rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.facebook.com/laehomes/">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                        <a className="btn btn-sm-square color-white bg-dark icon-contact rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.instagram.com/laehomes/">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                        <a className="btn btn-sm-square color-white bg-dark icon-contact rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.linkedin.com/company/lae-homes/?viewAsMember=true">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 className="mb-4">{t("contacto.comoAyudar")}</h3>
                            <form id="formContacto" onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="nombre" id="nombre" placeholder="Nombre y apellidos" required />
                                            <label htmlFor="nombre">{t("contacto.nombre")} <span style={{ color: 'red'}}>*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="email" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="email" id="email" placeholder="Email de contacto" required />
                                            <label htmlFor="email">{t("contacto.email")} <span style={{ color: 'red'}}>*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                        <PhoneInput ref={inputRef} onChange={(val,coun,ev,form) =>showPhone (val,coun,ev,form)} country={loc_country} inputProps={{
                                            name: 'telefono',
                                            id:"telefono",
                                            required: true,
                                            autoFocus: true
                                        }}  placeholder={t("contacto.telefono")} />
                                            {/* <input type="tel" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="telefono" id="telefono" placeholder="Teléfono" /> */}
                                            {/* <label htmlFor="telefono">Teléfono</label> */}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <select name="interesado" id="interesado" className="form-select border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} required>
                                                <option value="">{t("contacto.interesado")}</option>
                                                <option value="vender">{t("contacto.vender")}</option>
                                                <option value="comprar">{t("contacto.comprar")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control border-0 border-bottom" style={{ height: '200px', borderRadius: 0}} onChange={handleChange} name="observaciones" id="observaciones" placeholder="Observaciones"  required></textarea>
                                            <label htmlFor="observaciones">{t("contacto.observaciones")} <span style={{ color: 'red'}}>*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <input type="checkbox" className="form-check-input-register" style={{ marginRight: '10px'}} onChange={handleChange} name="terms" id="terms" required />
                                        <label htmlFor="terms">{t("contacto.terms1A")} <u><Link to="/politica-privacidad" target={"_blank"}>{t("politicaPrivacidad")}</Link></u> {t("contacto.terms1B")}</label>
                                    </div>
                                    <div className="col-12">
                                        <input type="checkbox" className="form-check-input-register" style={{ marginRight: '10px'}} onChange={handleChange} name="suscribir" id="suscribir" />
                                        <label htmlFor="suscribir" style={{ display: "inline"}}>{t("contacto.suscripcion")}</label>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-borde py-3 px-5" style={{ width: '200px'}} type="submit">{t("contacto.enviar")}</button>
                                    </div>
                                    <div className="col-12">
                                        <Link to="#" onClick={()=>{setShow(true)}}><small style={{ color: "#aeaeae"}}>{t("contacto.informacionBasicaPD")}</small></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <PoliticaContacto show={show} onHide={() => setShow(true)} />
            
            <Footer />
            <style jsx="true">{`
                .parallax{
                    background-image: url(${`'${Ciudad}'`});
                    min-height: 500px;
                    background-attachment: fixed;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                .bg-imagen{
                    background-image: url(${`'${bgImg}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .react-tel-input .flag-dropdown {
                    background-color:white !important;
                }
                .react-tel-input .form-control, .react-tel-input .flag-dropdown {
                    border-bottom: none !important;
                }
                .react-tel-input{
                    border-bottom: 1px solid #dee2e6 !important;
                }
                
            `}</style>
        </>

    );
}
 
export default Contacto;
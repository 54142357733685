import React from 'react';
import ImageGallery from 'react-image-gallery';

class Gallery extends React.Component {
    handleScreenChange = (isFullScreen, gal) => {
       
        const gallery = document.querySelectorAll('.image-gallery');
        if (gallery && gallery.length > gal) {
            const img_gallery = gallery[gal].querySelectorAll('.image-gallery-image');
          
            if (isFullScreen) {
                gallery[gal].classList.add('fullscreen');
                if (img_gallery) {
                    img_gallery.forEach(item => {
                        item.classList.add('image-gallery-fullscreen');
                    });
                }
            } else {
                gallery[gal].classList.remove('fullscreen');
                if (img_gallery) {
                    img_gallery.forEach(item => {
                        item.classList.remove('image-gallery-fullscreen');
                    });
                }
            }
        }
    };
    
    render() {
        return (          
           
            <ImageGallery
                originalClass={`img-${this?.props?.gal}`}
                items={this && this?.props && this?.props?.images}
                onScreenChange={(isFullScreen) => this.handleScreenChange(isFullScreen, this?.props?.gal)}
                ref={this?.props?.galleryRef}
            />
        
        );
    }
}

export default Gallery;
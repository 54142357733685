import React, { useState, useEffect } from 'react';
import Menu from "../static/menu";
import Footer from '../static/footer';
import { usePromociones } from '../hooks';
const Promociones = () => {
    const {
        data,
        fetchData,
        loading,
        formatData,
        limite, } = usePromociones();
    useEffect(()=>{
        fetchData({ offset: 1 });
    },[data?.rows]);

    console.log(data);
    return(
        <>
            <Menu/>

            <Footer/>
        </>
    );
}

export default Promociones;
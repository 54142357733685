import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({
    title,
    description = "La profesionalidad y la excelencia son las palabras que hacen de LAE HOMES el nuevo concepto inmobiliario. Una agencia diferente que nace con la vocación de ofrecer a los diferentes partners y clientes un servicio único y exclusivo que trascienda sus expectativas. Lujo al alcance de todos.",
    type = "website",
    image,
    ruta = '',
    refPropiedad,
    precioPropiedad
    } = {}) {
    return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title ? `${title} - LAE HOMES` : "LAE HOMES"}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={"https://laehomes.com" + ruta} />
            { /* End standard metadata tags */ }
            { /* Facebook tags */ }
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title ? `${title} - LAE HOMES` : "LAE HOMES"} />
            <meta property="og:description" content={description} />
            {image && <meta property="og:image" content={image} />}
            <meta property="og:url" content={"https://laehomes.com" + ruta} />
            {refPropiedad && <meta property="product:retailer_item_id" content={refPropiedad} />}
            {refPropiedad && <meta property="product:brand" content="LAE HOMES" />}
            {refPropiedad && <meta property="product:availability" content="available for order" />}
            {refPropiedad && precioPropiedad && <meta property="og:price:amount" content={precioPropiedad} />}
            {refPropiedad && precioPropiedad && <meta property="og:price:currency" content="EUR" />}
            
            { /* End Facebook tags */ }
            { /* Twitter tags */ }
            {/* <meta name="twitter:creator" content={name} /> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title ? `${title} - LAE HOMES` : "LAE HOMES"} />
            <meta name="twitter:description" content={description} />
            {image && <meta name="twitter:image" content={image} />}
            { /* End Twitter tags */ }

        </Helmet>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import Footer from "../static/footer";
import Slider0 from './../images/Slide-home-0.jpg'
// import { Link } from "react-router-dom";
import Inmueble1 from './../images/Inmueble-1.jpg'
import Inmueble2 from './../images/Inmueble-2.jpg'
import Inmueble3 from './../images/Inmueble-3.jpg'
import sinImagen from './../images/sin-imagen.jpg'
import ReactPaginate from 'react-paginate';
// import { provincias, ciudades} from '../providers/datos';
import { Link } from 'react-router-dom';
import handShake from './../images/firma/ico-handshake.png';
import diamond from './../images/firma/ico-diamond.png';
import herbs from './../images/firma/ico-herbs.png';
import house from './../images/firma/ico-house.png';
import sofa from './../images/firma/ico-sofa.png';
import top_rated from './../images/firma/ico-top-rated.png';
import juridico from './../images/firma/ico-juridico.png';
import financiero from './../images/firma/ico-financiero.png';
import { getPropiedades } from '../providers/api';
import { quitarAcentos } from '../providers/functions';
import { withRouter } from '../providers/withRouter';
import ResultadoForm from '../static/ResultadoPropiedades';
import FiltroPropiedades from '../static/FiltroPropiedades';
import { animateScroll as scroll } from 'react-scroll';
import { usePropiedades } from '../hooks';
import SEO from '../utilities/SEO';

const ListadoFiltros = (props) => {
    const { t } = useTranslation("global");
    const [datos, setDatos] = useState([]);
    const [datosFiltrados, setDatosFiltrados] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const PerPage = 50;
    const firstItemRef = useRef(null);
    const [filtros, setFiltros] = useState({
        published:true,
        Difconservacion: "Obra Nueva"
    });
    const {loading, data, fetchData} = usePropiedades();
    const scrollToTop = () => {
         // Obtener la referencia del elemento deseado (en este caso, el primer elemento)
        const firstItem = firstItemRef.current;
    
        if (firstItem) {
            const elementOffsetTop = firstItem.offsetTop; // Posición vertical del elemento en la página
            const elementHeight = firstItem.offsetHeight; // Altura del elemento

            // Calcular la posición a la mitad del elemento
            const scrollToPosition = elementOffsetTop + elementHeight / 2;

            // Realizar el scroll hasta la posición deseada
            scroll.scrollTo(scrollToPosition, {
                duration: 500, // Puedes ajustar la duración de la animación
                smooth: true, // Activa el scroll suave
                offset: -200, // Puedes ajustar un desplazamiento adicional si lo necesitas
            });
        }
    };
    const handleFiltroSubmit = async () => {
        // await fetchData({offset:0, limit:50, filtros:filtros});
        fetchData({ offset: 1, limit: 50, filtros: filtros });
        setOffset(1);
    };
    useEffect(() => {
        fetchData({offset:offset, limit:50, filtros:filtros});
    }, [offset]);
    
    const handlePageChange = async (e) => {
    
        const selectedPage = e.selected;
        const newOffset = selectedPage + 1; 
      
        setCurrentPage(selectedPage); 
        setOffset(newOffset); 
        
        await fetchData({ offset: newOffset, limit: PerPage, filtros: filtros });
     
    };
    
    const pageCount = Math.ceil(data.meta.total / 50);

    return (
        <>
            {SEO({title: t("propiedades.propiedades"), type: 'website', ruta: "/propiedades"})}
            <Menu />
            <div className="bg-imagen container-fluid d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        {/* <h1 className="display-4 text-white mb-3 text-white">{props.location.pathname === '/propiedades-exclusivas' ? t("propiedades.propiedadesExclusivas") : t("propiedades.otrasPropiedades")}</h1> */}
                        <h1 className="display-4 text-white mb-3 text-white">{t("propiedades.propiedades")}</h1>
                        {/* <p className="text-white">{t("obra.propiedadesText")}</p> */}
                    </div>
                </div>
            </div>
            {loading? 
                <>
                    <div className="container-xxl pt-5">
                        <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                            <div className="row gt-4">
                                <FiltroPropiedades handleFiltroSubmit={handleFiltroSubmit} setFiltros={setFiltros} filtros={filtros} />
                            </div>
                        </div>
                    </div>
                    {loading ? 
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border" role="status">
                            <span className="sr-only">{t("cargando")}</span>
                            </div>
                        </div>
                    :
                        <>
                            <ResultadoForm datosFiltrados={data.rows} />
                            <div className="mt-5">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    forcePage={currentPage} // Establecer la página actual en la paginación
                                />
                            </div>
                            {data.rows.length > 0 &&
                                <div className="mt-5">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        forcePage={currentPage} // Establecer la página actual en la paginación
                                    />
                                </div>
                            }
                        </>
                    }
                
                </>   
            
            :
                <>
                    <div className="container-xxl pt-5" ref={firstItemRef}>
                            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                                <div className="row gt-4">
                                        <FiltroPropiedades handleFiltroSubmit={handleFiltroSubmit} setFiltros={setFiltros} filtros={filtros} />
                                </div>
                            </div>
                    </div>
                    
                    {loading ? 
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border" role="status">
                            <span className="sr-only">{t("cargando")}</span>
                            </div>
                        </div>
                    :
                        <>
                            {data.rows.length > 0 &&
                                <div className="mt-5">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={4}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        forcePage={currentPage} 
                                    />
                                </div>
                            }
                            <ResultadoForm datosFiltrados={data.rows} />
                            {data.rows.length > 0 &&
                                <div className="mt-5">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={4}
                                        // onPageChange={handlePageChange}
                                        onPageChange={(selectedItem) => {
                                            handlePageChange(selectedItem); // Llama a la función original para manejar la paginación
                                            scrollToTop(); // Realiza el scroll hacia arriba
                                        }}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        forcePage={currentPage} // Establecer la página actual en la paginación
                                    />
                                </div>
                            }
                        </>
                    }
                </>
            }
            <Footer />
            <style jsx="true">{`
                    .bg-imagen{
                        background-image: url(${`'${Slider0}'`});
                        min-height: 400px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 100%;
                        margin: 0;
                    }                
            `}</style>
        </>
    );
};

export default withRouter(ListadoFiltros);
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from "react-i18next"
import i18next from 'i18next'

import i18n from './static/traducciones/i18n';

import global_es from "./static/traducciones/es/global_es.json";
import global_en from "./static/traducciones/en/global_en.json";
import global_fr from "./static/traducciones/fr/global_fr.json";
import GTMInitializer from './components/GoogleTagManager';
import { ModalProvider } from './providers/ModalContext';
 
import { AxiosInterceptor } from "./interceptors/interceptor";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources:{
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    fr:{
      global: global_fr,
    }
  }
})
AxiosInterceptor();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GTMInitializer>
      <I18nextProvider i18n={i18n} />
        <App />
      <I18nextProvider />
    </GTMInitializer>
  </React.StrictMode>
);

import React, { useState } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import HouseGoldMarkerIcon from './../images/marker_dorado_casita.png';
import HouseGrisMarkerIcon from './../images/marker_gris_casita.png';
import OfficeGoldMarkerIcon from './../images/marker_dorado_logo.png';
import OfficeGrisMarkerIcon from './../images/marker_gris_logo.png';

function MapContainer({coordenadas, oficinas, exclusiva, sucursal}) {

  const containerStyle = {
    width: '100%',
    height: '450px',
  };
  const [zoom, setZoom] = useState(coordenadas.length === 1 ? 17 : 6);
  let CustomMarkerIcon = ''
  // console.log(oficinas, exclusiva, sucursal)
  if(exclusiva){
    CustomMarkerIcon = HouseGoldMarkerIcon
  }
  if(!exclusiva && !oficinas && !sucursal){
    CustomMarkerIcon = HouseGrisMarkerIcon
  }
  if(oficinas){
    CustomMarkerIcon = OfficeGoldMarkerIcon
  }
  if(sucursal){
    CustomMarkerIcon = OfficeGrisMarkerIcon
  }
  const markerSize = {
    width: 37,
    height: 45,
  };

  /*const center = {
    lat: parseFloat(props.coordenadas[0].lat),
    lng: parseFloat(props.coordenadas[0].lng), 
  };*/
  /* const array = [
    { lat: parseFloat(36.621806015), lng: parseFloat(-4.500254146) }, 
    { lat: parseFloat(36.622806135), lng: parseFloat(-4.500254246) }, 
    { lat: parseFloat(36.623806255), lng: parseFloat(-4.500254346) } 
  ] */
  const markers = coordenadas
  /* props.coordenadas.forEach( c => {
    console.log(c)
    let datos = {lat:parseFloat(c.latitud), lng:parseFloat(c.longitud)}
    markers.push(datos)
  }) */
  /* const markers = [
    { lat: parseFloat(40.712776), lng: parseFloat(-74.005974) }, // Nueva York
    { lat: parseFloat(51.5074), lng: parseFloat(-0.1278) }, // Londres
    { lat: parseFloat(48.8566), lng: parseFloat(2.3522) } // París
  ] */
// console.log(markers)

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const currentZoom = mapRef.getZoom();
    setZoom(currentZoom);
    // console.log('Marker dragged to:', lat, lng);
  };

  let mapRef = null;
  const mapOptions = {
    center: markers[0],
    zoom: zoom,
    mapTypeId: 'roadmap',
    disableDefaultUI: false,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ],
    backgroundColor: '#f2f2f2',
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    rotateControl: true,
    draggable: true,
    gestureHandling: 'cooperative',
    clickableIcons: true,
    disableDoubleClickZoom: true,
    mapId:'cb9032ee08e759dd'
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyBCnuwMhXxoOwoYQFB6GR9Cbt2hmrw_2uI">
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={map => {
          mapRef = map;
        }}
      >
        {markers.map((marker, index) => (
            <MarkerF key={index} position={marker} icon={{
                url: CustomMarkerIcon,
                scaledSize: markerSize
              }} />
        ))}
        {/* <MarkerF position={center} draggable={true} onDragEnd={handleMarkerDragEnd} icon={{
          url: CustomMarkerIcon,
          scaledSize: markerSize
        }}/> */}
      </GoogleMap>
    </LoadScript>
  );
}
export default React.memo(MapContainer)
import axios from "axios";

const urlPath = "tipos";

export const tipos = async () => {
    try {
        const response = await axios.get(`${urlPath}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
// GTMInitializer.js
import ReactGA from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5MR7FV2M', // Reemplaza con tu ID de contenedor de GTM
};

ReactGA.initialize(tagManagerArgs);

export default function GTMInitializer(props) {
  return <>{props.children}</>;
}

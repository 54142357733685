import React from 'react';
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import handShake from './../images/firma/ico-handshake.png';
import diamond from './../images/firma/ico-diamond.png';
import herbs from './../images/firma/ico-herbs.png';
import house from './../images/firma/ico-house.png';
import sofa from './../images/firma/ico-sofa.png';
import top_rated from './../images/firma/ico-top-rated.png';
import juridico from './../images/firma/ico-juridico.png';
import financiero from './../images/firma/ico-financiero.png';
import mapa from './../images/firma/ico-mapa.png';
import Footer from '../static/footer';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import SEO from '../utilities/SEO';

const Firma = () => {
    const { t } = useTranslation("global");
    return ( 
    <>
        {SEO({title: t("firma.firma"), type: 'website', ruta: "/firma"})}
        <Menu />
        <div className="container-firma container-fluid d-flex" style={{alignItems: "center"}}>
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                    <h1 className="display-4 text-white mb-1 text-white">{t("firma.firma")}</h1>
                </div>
            </div>
        </div>
        <Linea1 />
        <div id="historia" className="container-xxl pd-5 pdb-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                    <p>{t('firma.historiaText1')}</p>
                    
                    <p> {t("firma.historiaText2")}</p>
                </div>
            </div>
        </div>
        <div className="container-firma-2 container-fluid pd-4">
        </div>
        <div id="concepto" className="container-xxl">
            <div className="row g-0">
                <div className="col-lg-6 pt-lg-5 pd-into wow fadeIn col-md-12 col-sm-12" data-wow-delay="0.5s" style={{visibility: "visible",animationDelay: "0.5s", animationName: "fadeInUp"}}>
                    <div className=" mt-lg-5">
                        <h1 className="display-6 mb-2">{t("firma.historia")}</h1>
                        <br/>
                        <p className="mb-2">{t('firma.conceptoText1')} <Link to="https://grupolae.net" target={"_blank"}><u>{t("firma.grupoLaeLink")}</u></Link> {t("firma.conceptoText2")}</p>
                    </div>
 
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 white_box">
                    <div className="bg-white pd-into2 c-float wow fadeIn" data-wow-delay="0.5s" style={{visibility: "visible",animationDelay: "0.5s", animationName: "fadeInUp"}}>
                        <ol id="p-list" className="pl-list">
                            <li className="pl-item">
                                <h5 className="text-bold">{t("firma.punto1Titulo")}</h5>
                                <p>{t("firma.punto1Text")}</p>
                            </li>
                            <li className="pl-item">
                                <h5 className="text-bold">{t("firma.punto2Titulo")}</h5>
                                {/* <p>{t("firma.punto2Textp1")} <i>{t("firma.punto2TextItalic")}</i> {t("firma.punto2Textp2")}</p> */}
                                <p>{t("firma.punto2Textp1")}</p>
                            </li>
                            <li className="pl-item">
                                <h5 className="text-bold">{t("firma.punto3Titulo")}</h5>
                                <p>{t("firma.punto3Text")}</p>
                            </li>
                        </ol >
                        <p >
                            {t("firma.parrafop1")} <i>{t("firma.parrafoItalic")}</i> {t("firma.parrafop2")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-firma-3 container-fluid pd-5 pdb-5">
        </div>
        <div id="equipo"className="container-xxl pdb-5">
            <div className="pt-lg-5 wow fadeIn" data-wow-delay="0.5s" style={{visibility: "visible",animationDelay: "0.5s", animationName: "fadeInUp"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-6 mb-1 ">{t("firma.laeHomes")}</h1>
                    </div>
                </div>
                <div className="pt-lg-5 wow fadeIn" data-wow-delay="0.5s" style={{visibility: "visible",animationDelay: "0.5s", animationName: "fadeInUp"}}>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={handShake} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion1Titulo")}</h5>
                            <p className="mb-0"> {t("firma.opcion1Text")}</p>
                        </div>  
                        <div className="col-lg-4 col-md-6 text-center ">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={diamond} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion2Titulo")}</h5>
                            <p>{t("firma.opcion2Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={top_rated} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion3Titulo")}</h5>
                            <p>{t("firma.opcion3Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={sofa} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion4Titulo")}</h5>
                            <p>{t("firma.opcion4Text")}</p>
                        </div>  
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={house} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion5Titulo")}</h5>
                            <p>{t("firma.opcion5Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={herbs} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion6Titulo")}</h5>
                            <p>{t("firma.opcion6Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={juridico} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion7Titulo")}</h5>
                            <p>{t("firma.opcion7Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={financiero} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion8Titulo")}</h5>
                            <p>{t("firma.opcion8Text")}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="btn-square mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img className="img-fluid" src={mapa} alt="Icon"/>
                            </div>
                            <h5 className="text-bold">{t("firma.opcion9Titulo")}</h5>
                            <p>{t("firma.opcion9Text")}</p>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
        <div className="container-firma-4 container-fluid pd-4 pdb-5">
        </div> 
        <Footer />
    </> 
    );
}
 
export default Firma;
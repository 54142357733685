import axios from "axios";

let config = {
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
    
    //'Content-Type': 'application/json;',
    "Access-Control-Allow-Origin": "*",
  }
}
let configData = {
  headers:{
    'Content-Type':'multipart/form-data', 
    "Access-Control-Allow-Origin": "*", 
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
  }
}

const { REACT_APP_API_URL } = process.env;


// PROPIEDADES
export const getPropiedades = async () => {
  try {
    const response = await  axios.get(`${REACT_APP_API_URL}/propiedades/list`, config);
    return response.data;

  }catch (err) {
    console.log(err);
  }
}
export const getPropiedadesOBN = async () => {
  try {
    const response = await  axios.get(`${REACT_APP_API_URL}/propiedades/obra-nueva/get/list`, config);
    return response.data;

  }catch (err) {
    console.log(err.response);
  }
}
export const getPropiedad = async (ref) => {
  try {
    const response = await  axios.get(`${REACT_APP_API_URL}/propiedades/propiedad/${ref}`, config);
    
    return response.data;


  }catch (err) {
    console.error(err.response);
    return err.response;
  }
}
export const getPropiedadObraNueva = async (ref) => {
  try {
    const response = await  axios.get(`${REACT_APP_API_URL}/propiedades/obra-nueva/${ref}`, config);
    // console.log(response)
    return response.data;

  }catch (err) {
    console.error(err.response);
    return err.response;
  }
}
export const downloadFilePropertie = async (id_doc, nombre) => {
   
  const response = await axios.get(`${REACT_APP_API_URL}/propiedades/web/docs/download/${id_doc}`, {...config, responseType: 'arraybuffer'}).then(res => {
      return res;
  }).catch(error => {
      console.log();
      return error.response.data.error;
  });
  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${nombre}.pdf`); 
  document.body.appendChild(link);
  link.click();
  
  window.URL.revokeObjectURL(url);
  
  return response;
}
// PROVINCIAS
export const getProvincias = async () => {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}/provincias`, config)
    response.data.shift();
    // console.log(response);
    return response;

  } catch (error) {
    console.error(error);
  }
}
export const getCiudades = async () => {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}/ciudades`, config);
    response.data.shift();
    // console.log(response);
    return response;

  } catch (error) {
    console.error(error);
  }
}
export const getTipos = async () => {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}/tipos`, config);
    // console.log( response)
    response.data.shift();
    // console.log(response);
    return response;

  } catch (error) {
    console.error(error);
  }
}
// ARTICLES

export const getArticles = async () => {
  try {
    const response = await axios(`${REACT_APP_API_URL}/articles/list/all`);
    return response;
  } catch (error) { 
    console.log(error);
  }

}

export const getArticle = async (id) => {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}/articles/list/show/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
}
import { useState } from "react";
import { listSucursales } from "../service";

function useSucursales(){
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: []
    });
    const[loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data.map((row) => ({
            id: row.id,
            id_ext: row.id_ext,
            provincia: row.provincia.includes('Alicante') ? 'Alicante' :
                   row.provincia.includes('València') || row.provincia.includes('Valencia') ? 'Valencia' :
                   row.provincia,
            municipio: row.municipio.includes('Alicante') ? 'Alicante' :
                   row.municipio.includes('València') || row.municipio.includes('Valencia') ? 'Valencia' :
                   row.municipio,
            direccion: row.direccion,
            telefono: row.telefono,
            cp: row.cp,
            email: row.email,
            latitud:row.latitud,
            longitud:row.longitud,
        }));
    }
    const fetchData = async({offset, limit, filtros}) => {
        setLimite(limit);
        const payload = {
            params: {page: offset, limit},
            filtros
        };
        try {
            setLoading(true);
            let response = await listSucursales(payload);   
            const { meta, data } = response;
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        limite,
    };
}
export default useSucursales;
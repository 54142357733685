import './App.css';
import Inicio from './routes/Inicio'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Firma from './routes/Firma';
import Servicios from './routes/Servicios';
import Contacto from './routes/Contacto';
import Trends from './routes/Trends';
import Trend from './routes/Trend';
import Propiedades from './routes/Propiedades';
import Propiedad from './routes/Propiedad';
import FichaObranueva from './static/fichaObranueva';
import ObraNueva from './routes/obraNueva';
import PoliticaPrivacidad from './routes/PoliticaPrivacidad';
import PoliticaCookies from './routes/PoliticaCookies';
import Formacion from './routes/Formacion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getProvincias, getCiudades, getTipos } from './providers/api';
import { useEffect, useContext, useState } from 'react';
import Registro from './routes/Registro';
import AvisoLegal from './routes/AvisoLegal';
import PropiedadesFiltro from './routes/PropiedadesFiltro';
import ObraNuevaFiltro from './routes/obraNuevaFiltro';
import Oficinas from './routes/oficinas';
import RedireccionInmovilla from './routes/RedireccionInmovilla';
import Promociones from './routes/Promociones';
import { ModalProvider, ModalContext } from './providers/ModalContext';
import  ModalPromos  from './components/ModalPromos';
import { getCookie } from './utilities/createCookie';
const AppRoutes = () => {
  const location = useLocation();
  const {isModalVisible, setIsModalVisible } = useContext(ModalContext);
  useEffect(() => {
    console.log(getCookie("modal-promos"));
    if (getCookie("modal-promos") === false && (location.pathname === '/' || location.pathname === '/inicio' || location.pathname === '/obra-nueva') ) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [location, /*setIsModalVisible*/]);

  return (
    <Routes>
      <Route path='/*' element={<Inicio />}/>
      <Route path='/inicio' element={<Inicio />}/>
      <Route exact path='/firma' element={<Firma />} />
      <Route exact path='/firma/:sec' element={<Firma />} />
      <Route path='/propiedades' element={<PropiedadesFiltro />}/>
      <Route path='/obra-nueva/:ref' element={<FichaObranueva />}/>
      <Route path='/propiedades/:ref' element={<Propiedad />}/>
      <Route path='/servicios' element={<Servicios />}/>
      <Route path='/blog' element={<Trends />}/>
      <Route path='/blog/:id' element={<Trend />}/>
      <Route path='/contacto' element={<Contacto />}/>
      <Route path='/oficinas' element={<Oficinas />}/>
      <Route path='/registro' element={<Inicio />}/>
      <Route path='/obra-nueva' element={<ObraNuevaFiltro />}/>
      <Route path='/politica-privacidad' element={<PoliticaPrivacidad />}/>
      <Route path='/aviso-legal' element={<AvisoLegal />}/>
      <Route path='/politica-cookies' element={<PoliticaCookies />}/>
      <Route path='/ficha/index.php' element={<RedireccionInmovilla />}/>
      <Route path='/promociones' element={<Promociones/>}/>
      <Route path='/formacion' element={<Formacion/>}/>
    </Routes>
  );
};
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ModalProvider>
          <AppRoutes />
          <ModalPromos />
        </ModalProvider>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}


export default App;

import React, { createContext, useState, useEffect } from 'react';
import { usePromociones } from '../hooks'; 
import { getCookie } from '../utilities/createCookie';
export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const {loading, data, fetchData} = usePromociones();
    const [isModalVisible, setIsModalVisible] = useState(getCookie("modal-promos"));
    const [number, setNumber] = useState(null);
  
    const generateRandomNumbers = () => {
        
        setNumber( Math.floor(Math.random() * 2) + 1);
    };
    useEffect(() => {
        generateRandomNumbers();
        fetchData({ offset: 1 });
           
    }, []);
    return (
    
    <ModalContext.Provider value={{ isModalVisible, setIsModalVisible, number, data }}>
        {children}
    </ModalContext.Provider>
    );
};

import axios from "axios";

const urlPath = "propiedades";

export const properties = async (data) => {
    try {

        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });
        
        return response.data;
    } catch (error) {
      console.log(error);
    }
};
export const getPropertie = async (ref) => {
    try {
        const response = await axios.get(`${urlPath}/obra-nueva/${ref}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getPropertieRefFromIdInmovilla = async (idInmovilla) => {
    try {
        const response = await axios.get(`${urlPath}/inmovilla/${idInmovilla}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
import React, { useState, useEffect } from 'react'
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import Footer from "../static/footer";
import Slider0 from './../images/Slide-home-0.jpg'
// import { Link } from "react-router-dom";
import Inmueble1 from './../images/Inmueble-1.jpg'
import Inmueble2 from './../images/Inmueble-2.jpg'
import Inmueble3 from './../images/Inmueble-3.jpg'
import sinImagen from './../images/sin-imagen.jpg'
import ReactPaginate from 'react-paginate';
import {provincias} from '../providers/datos'
import { Link } from 'react-router-dom';
import handShake from './../images/firma/ico-handshake.png';
import diamond from './../images/firma/ico-diamond.png';
import herbs from './../images/firma/ico-herbs.png';
import house from './../images/firma/ico-house.png';
import sofa from './../images/firma/ico-sofa.png';
import top_rated from './../images/firma/ico-top-rated.png';
import juridico from './../images/firma/ico-juridico.png';
import financiero from './../images/firma/ico-financiero.png';
import { getPropiedades } from '../providers/api';
import { quitarAcentos } from '../providers/functions';
import { withRouter } from '../providers/withRouter';

const ObraNueva = (props) => {
    const { t } = useTranslation("global");
    /* const property = [
        {id:1, nombre: "apartamento duplex", precio: "305.000", provincia: "Barcelona", direccion: "esta es la dirección 1", baños: 1, metros: 85, garaje: 1, habitaciones: 2, imagen: Inmueble1},
        {id:2, nombre: "piso amplio", precio: "210.000", provincia: "Madrid", direccion: "esta es la dirección 2", baños: 1, metros: 70, garaje: 1, habitaciones: 3, imagen: Inmueble2},
        {id:3, nombre: "piso exterior", precio: "195.000", provincia: "Barcelona", direccion: "esta es la dirección 3", baños: 1, metros: 65, garaje: 0, habitaciones: 1, imagen: Inmueble3},
        {id:4, nombre: "apartamento espacioso", precio: "300.000", provincia: "Guipúzcoa", direccion: "esta es la dirección 4", baños: 2, metros: 90, garaje: 0, habitaciones: 3, imagen: Inmueble1},
        {id:5, nombre: "chalet", precio: "1.120.000", provincia: "Murcia", direccion: "esta es la dirección 5", baños: 3, metros: 260, garaje: 2, habitaciones: 5, imagen: Inmueble2},
        {id:6, nombre: "piso", precio: "250.000", provincia: "Almería", direccion: "esta es la dirección 6", baños: 1, metros: 80, garaje: 0, habitaciones: 3, imagen: Inmueble3},
        {id:7, nombre: "piso exterior amueblado", precio: "295.000", provincia: "Barcelona", direccion: "esta es la dirección 7", baños: 2, metros: 85, garaje: 0, habitaciones: 2, imagen: Inmueble1},
        {id:7, nombre: "apartamento", precio: "415.000", provincia: "Barcelona", direccion: "esta es la dirección 8", baños: 2, metros: 105, garaje: 1, habitaciones: 3, imagen: Inmueble2},
        {id:7, nombre: "piso amueblado", precio: "320.000", provincia: "Barcelona", direccion: "esta es la dirección 9", baños: 1, metros: 80, garaje: 0, habitaciones: 2, imagen: Inmueble3},
    ] */
    
    const perPage= 12
    const [offset, setOffset] = useState(0)
    const [selectedPage, setCurrentPage] = useState(0)
    const [pageCount, setpageCount] = useState(0)
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState('')
    const [provincia, setProvincia] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [property, setProperty] = useState([])
    const [propiedades, setPropiedades] = useState([])

    const handleChange = (e) => {
        setOffset(0)
        setCurrentPage(0)
        // console.log("Provincia "+ e.target.value)
        setProvincia(e.target.value)
    }

    function changePage (e) {
        const d = e.selected * perPage;
        setCurrentPage(e.selected)
        setOffset(d)
        receivedData(d, perPage)
    };

    function receivedData(o, p) {
        setPropiedades(propiedades)
        setpageCount(Math.ceil(propiedades.length / perPage))
        setIsLoading(false)
    }

    useEffect(() => {
        // receivedData(offset, perPage)
        let propiedades = []
        let min = 50000
        let max = 2000000
        let obj = {}
       
    },[]);


    const filtrarDatos = () => {
        // console.log("LA CIUDAD SELECCIONADA PARA BUSCAR ES "+ provincia)
        let props = []
        property.forEach( p => {
            // console.log(p.provincia, provincia)
            if(p.provincia === provincia){
                props.push(p)
                // console.log(p)
            }
            if(provincia === ''){
                props = property
            }
            // console.log(props)
            setProvinciaSeleccionada(provincia)
            setPropiedades(props)
            setpageCount(Math.ceil(props.length / perPage))
            setIsLoading(false)
        })  
    }

    return ( 
    <>
        <Menu />
        <div className="bg-imagen container-fluid d-flex" style={{alignItems: "center"}}>
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                    <h1 className="display-4 text-white mb-3 text-white">{t("obra.obra")} sdasdsa</h1>
                    {/* <p className="text-white">{t("obra.propiedadesText")}</p> */}
                    <div className="my-3">
                        <div className="buscadorProps">
                            <select value={provincia} onChange={handleChange} name="ciudades" id="ciudades" style={{ width: "300px", padding: '7px', marginRight: '10px'}}>
                                <option value="">{t("filtros.provincias")}</option>
                                {provincias.map((p,i) => {
                                    return(
                                        <option key={i} value={quitarAcentos(p.toUpperCase())}>{p}</option>                                        
                                    )
                                })}
                            </select>
                            <button className="btn btn-primary text-white" onClick={filtrarDatos}>{t("filtros.buscar")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h1>asdsadasd</h1>
        <div className="container-xxl pt-5">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                <div className="row g-4">

                    {isLoading && 
                        <div className="d-flex justify-content-center my-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">{t("cargando")}</span>
                            </div>
                        </div>
                    }

                    {propiedades.length === 0 && !isLoading ?
                        <div className='text-center my-5 display-6' style={{ fontWeight: 'lighter' }}>{t("obra.nopropiedades")}</div> :
                        propiedades?.slice(offset, offset + perPage).map((p,i) => {
                            return(
                                <div key={i} className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative h-100">
                                        <div className="service-text">
                                    
                                            <div className="bg-light mx-auto">
                                                {/* <div className='etiqueta'><span>COMPRA</span></div> */}
                                                <Link to={`/obra-nueva/${p.ref}`} target={'_blank'}><img className="img-fluid" src={p.imagen} alt="Imagen" style={{height:"333px"}} /></Link>
                                            </div>
                                            <div className='p-5 property-details-container'>
                                                <Link to={`/obra-nueva/${p.ref}`} target={'_blank'}>
                                                    <div className='p-3 property-details' >
                                                        {/* <Link to={`/obra-nueva/${p.cod_ofer}`} className="btn btn-primary rounded-circle" style={{ position: 'absolute', top: '-20px', right: '20px'}}><i className='fa fa-plus'></i></Link> */}
                                                        <h5 className="mb-3">{p.nombre}</h5>
                                                        <h6 className="mb-3">{p.zona}</h6>
                                                        <p className="mb-2 text-price">{p.precio}</p>
                                                        <p className="mb-2 color-primary"><b>{p.provincia}</b></p>
                                                        <div className='property-info'>
                                                            <div><i className='fa fa-bed me-1'></i>{p.habitaciones}</div>
                                                            <div><i className="fa fa-shower me-1"></i>{p.baños}</div>
                                                            <div><i className='fa fa-car me-1'></i>{p.garaje}</div>
                                                            <div><i className='fa fa-ruler-combined me-1'></i>{p.metros}m2</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="p-3">
                        {propiedades.length > 0 && 
                            <div className='text-center'>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={changePage}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        
        <Footer />
        <style jsx="true">{`
            .bg-imagen{
                background-image: url(${`'${Slider0}'`});
                min-height: 400px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                margin: 0;
            }                
        `}</style>
    </> 
    );
}
 
export default withRouter(ObraNueva);
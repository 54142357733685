import React, { useState, useEffect, useRef } from 'react';
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import Footer from "../static/footer";
import Slider0 from './../images/Slide-home-0.jpg';
import ReactPaginate from 'react-paginate';
import { withRouter } from '../providers/withRouter';
import FiltroObranueva from '../static/FiltroObraNueva';
import ResultadoObraNueva from '../static/ResultadoObraNueva';
import { animateScroll as scroll } from 'react-scroll';
import usePropiedades from '../hooks/usePropiedades';
import SEO from '../utilities/SEO';

const ObraNuevaFiltros = () => {
  const {loading, data, fetchData} = usePropiedades();
  const { t } = useTranslation("global");
  const [offset, setOffset] = useState(0);
  const [filtros, setFiltros] = useState({conservacion: "Obra Nueva", published: true});
  const firstItemRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const PerPage = 50;
  const handleFiltroSubmit = (e) => {
    e.preventDefault();
    fetchData({ offset: 1, limit: 50, filtros: filtros });
    setOffset(1);
  };

  const scrollToTop = () => {
    const firstItem = firstItemRef.current;

    if (firstItem) {
      const elementOffsetTop = firstItem.offsetTop;
      const elementHeight = firstItem.offsetHeight;
      const scrollToPosition = elementOffsetTop + elementHeight / 2;

      scroll.scrollTo(scrollToPosition, {
        duration: 500,
        smooth: true,
        offset: -200,
      });
    }
  };
  
  useEffect(() => {
    fetchData({ offset: offset, limit: 50, filtros: filtros });
  }, [offset]);

  const handlePageChange = async (e) => {
    const selectedPage = e.selected;
    const newOffset = selectedPage + 1; 
  
    setCurrentPage(selectedPage); 
    setOffset(newOffset); 
    
    await fetchData({ offset: newOffset, limit: PerPage, filtros: filtros });
  };

  const pageCount = Math.ceil(data.meta.total / 50);
  
  return (
    <>
      {SEO({title: t("obra.obra"), type: 'website', ruta: "/obra-nueva"})}
      <Menu />
      <div className="bg-imagen container-fluid d-flex" style={{alignItems: "center"}}>
        <div className="container">
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
            <h1 className="display-4 text-white mb-3 text-white">{t("obra.obra")}</h1>
          </div>
          <div className="text-center mx-auto wow fadeInUp back-white" data-wow-delay="0.1s" style={{maxWidth: "950px", padding:"15px 5px 0px 15px"}}> 
            <div className="row gt-4 ">
              <FiltroObranueva handleFiltroSubmit={handleFiltroSubmit} setFiltros={setFiltros} filtros={filtros}/> 
            </div>
          </div> 
        </div>
      </div>
      <Linea1/>
      <>
        <div className="container-xxl pt-5" ref={firstItemRef}>
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "1200px"}}>
            <div className='pl-5 pr-5 pt-5 '>
              <div className="row gt-4">
                {/* Componente de filtro */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </div>
        {loading ? 
          <div className="d-flex justify-content-center my-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("cargando")}</span>
            </div>
          </div>
        :
          <>
            <ResultadoObraNueva datosFiltrados={data.rows}/> 
          </>
        }
        <div className="mt-5">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(selectedItem) => {
              handlePageChange(selectedItem);
              scrollToTop();
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          
          />
        </div>
      </>
      <Footer />
      <style jsx="true">{`
        .bg-imagen{
            background-image: url(${`'${Slider0}'`});
            min-height: 400px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            margin: 0;
        }
        .back-white {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 20px;
        }
        .pl-5{
          padding-left: 3rem !important;
        }
        .pr-5{
          padding-right: 3rem !important;
        }
      `}</style>
    </>
  );
};

export default withRouter(ObraNuevaFiltros);

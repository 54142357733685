import { useState } from "react";
import { tipos } from "../service";

function useTiposPropiedades () {
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true);
  
    const fetchData = async() => {
        try {
            let response = await tipos();
            setData(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return {
        data,
        fetchData,
        loading,
        setLoading
    };
}

export default useTiposPropiedades;

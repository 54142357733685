import Footer from "../static/footer";
import Linea1 from "../static/linea1";
import Menu from "../static/menu";
import Img1 from "./../images/Servicios/Banner-superior-servicios.jpg";
import Img2 from "./../images/Servicios/img-servicio-compra.jpg";
import Img3 from "./../images/Servicios/img-servicio-venta.jpg";
import Img4 from "./../images/Servicios/img-servicio-aaff.jpg";
import aNotarial from "./../images/Servicios/a-notarial.png";
import { useTranslation } from "react-i18next";
import SEO from "../utilities/SEO";

const Servicios = () => {
    const { t, i18n } = useTranslation("global");

    const idioma = localStorage.getItem('language');
    
    return ( 
        <>
            {SEO({title: t("servicios.servicios"), type: 'website', ruta: "/servicios"})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">{t("servicios.servicios")}</h1>
                    </div>
                </div>
            </div>
            <Linea1/>
            {/* COMPRA CON */}
            <div id="compra" className="container-fluid p-0">
                <div className="container-fluid px-0">
                    <div className="row g-0 w-100">
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div id="cimg" className="bg-imagen-aux-1"></div>
                            {/* <img src={Img2} alt="Imagen" /> */}
                        </div>
                        <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 bg-color-gray" data-wow-delay="0.5s">
                            <h2 className="mb-4">{t("servicios.compra")}</h2>
                            <p className="fs-6">{t("servicios.compraP1")}</p>
                            <p className="fs-6">{t("servicios.compraP2")}</p>
                            <p className="fs-6">{t("servicios.compraP3")}</p>
                            
                            <p className="fs-6">{t("servicios.ventajaP")}</p>
                            <div className="accordion accordion-flush" id="accordionFlushCompra" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingCompraOne">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCompraOne" aria-expanded="false" aria-controls="flush-collapseCompraOne">
                                    {t("servicios.compraOpt.experiencia")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseCompraOne" className="accordion-collapse collapse" aria-labelledby="flush-headingCompraOne" data-bs-parent="#accordionFlushCompra">
                                    <div className="accordion-body">{t("servicios.compraOpt.experienciaP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingCompraTwo">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCompraTwo" aria-expanded="false" aria-controls="flush-collapseCompraTwo">
                                        {t("servicios.compraOpt.asesoramientoJuridico")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseCompraTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingCompraTwo" data-bs-parent="#accordionFlushCompra">
                                    <div className="accordion-body">{t("servicios.compraOpt.asesoramientoJuridicoP")}</div>
                                    <img alt={t("servicios.compraOpt.asesoramientoJuridico")} src={aNotarial} width={"20%"}/>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingCompraThree">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCompraThree" aria-expanded="false" aria-controls="flush-collapseCompraThree">
                                    {t("servicios.compraOpt.asesoramientoFinanciero")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseCompraThree" className="accordion-collapse collapse" aria-labelledby="flush-headingCompraThree" data-bs-parent="#accordionFlushCompra">
                                    <div className="accordion-body">{t("servicios.compraOpt.asesoramientoFinancieroP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingCompraFour">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCompraFour" aria-expanded="false" aria-controls="flush-collapseCompraFour">
                                        {t("servicios.compraOpt.tasacion")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseCompraFour" className="accordion-collapse collapse" aria-labelledby="flush-headingCompraFour" data-bs-parent="#accordionFlushCompra">
                                    <div className="accordion-body">{t("servicios.compraOpt.tasacionP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingCompraFive">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCompraFive" aria-expanded="false" aria-controls="flush-collapseCompraFive">
                                    {t("servicios.compraOpt.implantacion")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseCompraFive" className="accordion-collapse collapse" aria-labelledby="flush-headingCompraFive" data-bs-parent="#accordionFlushCompra">
                                    <div className="accordion-body">{t("servicios.compraOpt.implantacionP")}</div>
                                    </div>
                                </div>
                            </div>
                            <p className="fs-6 mt-4">{t("servicios.compraP4")}</p>
                            {/* <button type="button" className="btn btn-borde fs-7 py-2 px-3 mt-3">{t("servicios.procesobtn")}</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                        <h2 className="mb-4">{t("servicios.modelo")}</h2>
                        <div className="row circles-wrap" style={{ justifyContent: 'center', alignItems: 'center'}}>
                            <div className="m-4 border-color-primary py-4 service-circle">
                                <div>
                                    <h1 className="color-primary mb-3">1</h1>
                                    <p className="fs-6">{t("servicios.modeloPunto1")}</p>
                                </div>
                            </div>
                            <div id="lc-1" className="linea-circulos"></div>
                            <div className="m-4 border-color-primary py-4 service-circle">
                                <div>
                                    <h1 className="color-primary mb-3">2</h1>
                                    <p className="fs-6">{t("servicios.modeloPunto2")}</p>
                                </div>
                            </div>
                            <div id="lc-2" className="linea-circulos"></div>
                            <div className="m-4 border-color-primary py-4 service-circle">
                                <div>
                                    <h1 className="color-primary mb-3">3</h1>
                                    <p className="fs-6">{t("servicios.modeloPunto3")}</p>
                                </div>
                            </div>
                            <div id="lc-3" className="linea-circulos"></div>
                            <div className="m-4 border-color-primary py-4 service-circle">
                                <div>
                                    <h1 className="color-primary mb-3">4</h1>
                                    <p className={"fs-6"}>{t("servicios.modeloPunto4")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* VENDE CON */}
            <div id="vcont" className="container-fluid p-0">
                <div id="vende"className="container-fluid px-0">
                    <div className="row g-0 w-100">
                        <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 bg-color-gray altContent" id="altContent" data-wow-delay="0.5s">
                            <h2 className="mb-4">{t("servicios.vende")}</h2>
                            <p className="fs-6">{t("servicios.vendeP1")}</p>
                            <p className="fs-6">{t("servicios.vendeP2")}</p>
                            <div className="accordion accordion-flush" id="accordionFlushVende" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeOne">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeOne" aria-expanded="false" aria-controls="flush-collapseVendeOne">
                                    {t("servicios.vendeOpt.profesionalidad")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseVendeOne" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeOne" data-bs-parent="#accordionFlushVende">
                                    <div className="accordion-body">{t("servicios.vendeOpt.profesionalidadP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeTwo">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeTwo" aria-expanded="false" aria-controls="flush-collapseVendeTwo">
                                        {t("servicios.vendeOpt.tasacion")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseVendeTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeTwo" data-bs-parent="#accordionFlushVende">
                                    <div className="accordion-body">{t("servicios.vendeOpt.tasacionP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeFour">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeFour" aria-expanded="false" aria-controls="flush-collapseVendeFour">
                                        {t("servicios.vendeOpt.home")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseVendeFour" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeFour" data-bs-parent="#accordionFlushVende">
                                    <div className="accordion-body">
                                        <p>{t("servicios.vendeOpt.homeP1")}</p>
                                        <p>{t("servicios.vendeOpt.homeP2")}</p>
                                        <p>{t("servicios.vendeOpt.homeP3")}</p>
                                        <p>{t("servicios.vendeOpt.homeP4")}</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeSix">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeSix" aria-expanded="false" aria-controls="flush-collapseVendeSix">
                                        {t("servicios.vendeOpt.foto")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseVendeSix" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeSix" data-bs-parent="#accordionFlushVende">
                                        <div className="accordion-body">{t("servicios.vendeOpt.fotoP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeSeven">
                                    <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeSeven" aria-expanded="false" aria-controls="flush-collapseVendeSeven">
                                        {t("servicios.vendeOpt.marketing")}
                                    </button>
                                    </h2>
                                    <div id="flush-collapseVendeSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeSeven" data-bs-parent="#accordionFlushVende">
                                        <div className="accordion-body">{t("servicios.vendeOpt.marketingP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeEight">
                                        <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeEight" aria-expanded="false" aria-controls="flush-collapseVendeEight">
                                            {t("servicios.vendeOpt.garantia")}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseVendeEight" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeEight" data-bs-parent="#accordionFlushVende">
                                        <div className="accordion-body">{t("servicios.vendeOpt.garantiaP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeNine">
                                        <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeNine" aria-expanded="false" aria-controls="flush-collapseVendeNine">
                                            {t("servicios.vendeOpt.transparencia")}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseVendeNine" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeNine" data-bs-parent="#accordionFlushVende">
                                        <div className="accordion-body">{t("servicios.vendeOpt.transparenciaP")}</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingVendeTen">
                                        <button className="accordion-button collapsed py-2 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseVendeTen" aria-expanded="false" aria-controls="flush-collapseVendeTen">
                                            {t("servicios.vendeOpt.acceso")}
                                        </button>
                                    </h2>
                                    <div id="flush-collapseVendeTen" className="accordion-collapse collapse" aria-labelledby="flush-headingVendeTen" data-bs-parent="#accordionFlushVende">
                                        <div className="accordion-body">{t("servicios.vendeOpt.accesoP")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div id="vimg" className="bg-imagen-aux-2" ></div>
                            {/* <img src={Img3} alt="Imagen" id="image2" style={{ objectFit: "cover", objectPosition: "top"}} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div id="ofcont" className="container-fluid p-0">
                <div id="aaff" className="container-fluid px-0">
                    <div className="row g-0 w-100">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-md-1 order-sm-2 order-xs-2 wow fadeInUp" data-wow-delay="0.1s">
                            <div id="ofimg" className="bg-imagen-aux-3"></div>
                            {/* <img src={Img2} alt="Imagen" /> */}
                        </div>
                        <div id="oftext" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-md-2 order-sm-1 order-xs-1 wow fadeInUp px-5 py-5 bg-color-gray" data-wow-delay="0.5s">
                            <h2 className="mb-4">{t("servicios.aaff")}</h2>
                            <p className="fs-6">{t("servicios.aaffP1p1")} <b>{t("servicios.aaffP1Bold")}</b> {t("servicios.aaffP1p2")}</p>
                            <p className="fs-6">{t("servicios.aaffP2")} <b>{t("servicios.aaffP2Bold")}</b></p>
                            <p className="fs-5"><b>{t("servicios.ofrecemos")}</b></p>
                            <ul>
                                <li className="lista-serv">{t("servicios.ofrecemosP1")}</li>
                                <li className="lista-serv">{t("servicios.ofrecemosP2")}</li>
                                <li className="lista-serv">{t("servicios.ofrecemosP3")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                        <p className="fs-6 mb-4" style={{fontStyle: 'italic', fontWeight: 'lighter'}}>{t("servicios.parrafo")}</p>
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .bg-imagen-aux-1{
                    background-image: url(${`'${Img2}'`});
                    min-height: 550px;
                    height:100%;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .bg-imagen-aux-2{
                    background-image: url(${`'${Img3}'`});
                    min-height: 550px;
                    height:100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .bg-imagen-aux-3{
                    background-image: url(${`'${Img4}'`});
                    min-height: 550px;
                    height:100%;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .linea-circulos{
                    height: 1px;
                    max-width: 25px;
                    background: #384043;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                }
                
            `}</style>
        </>
    );
}
 
export default Servicios;
import React, { useEffect, useState, useReducer, useRef } from 'react';
import Footer from '../static/footer';
import Menu from '../static/menu';
import { getPropiedadObraNueva, downloadFilePropertie } from '../providers/api';
import { withRouter } from "../providers/withRouter";
import { useTranslation } from "react-i18next";
import Maps from './Maps';
import sinImagen from './../images/sin-imagen.jpg'
import Linea1 from './linea1';
import Gallery from './gallery';
import { useParams } from 'react-router-dom';
import { getPropertie, properties } from '../service';
import SEO from '../utilities/SEO';


// OBJ
const FichaObranueva = () => {
    // const [propiedad, setPropiedad] = useReducer(reducerObranueva, []);
    const [propiedad, setPropiedad] = useState({});
    const [show, setShow] = useState(false);
    const [componente, setComponente] = useState(false);
    const [fotos, setFotos] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [numIdioma, setNumIdioma] = useState(0);
    const galleryRef = useRef(null);
    const [no_existe, setNotExist] = useState({});
    const pics = [];
    const { t, i18n } = useTranslation("global");
    const [fotosGaleria, setFotosgaleria] = useState([]);
    const [textos, setTextos] = useState({});
    const param = useParams();
    let idioma = i18n.language;
    const [load, setLoad] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const { ref } = param;
    // console.log(ref);
    const loadIdioma = async () => {
        let numI = 0
        switch (idioma) {
            case 'es':
                numI = 1;
                break;
            case 'en':
                numI = 2;
                break;
            case 'fr':
                numI = 4;
                break;
            default:
                numI = 1;
        }
        setNumIdioma(numI);
    }
    const fetchData = async () => {
        const data = await getPropertie(ref);
        if (data == "") {
            setNotExist({ status: 404 });
        } else {
            setTextos(data?.textos.find(txt => txt?.id_idioma === numIdioma));
            setFotos(data.fotos);
            setFotosgaleria(data.fotos.map(foto => {
                let original = foto?.url;
                let thumbnail = foto?.url;
                return { original, thumbnail }
            }));
            setDocumentos(data.documentos.filter(doc => doc.status !== false));
            setPropiedad(data);
        }

    }

    useEffect(() => {
        window.fbq('track', 'ViewContent', {
            content_ids: [ref],
        });
    }, [ref])

    useEffect(() => {
        fetchData();
        loadIdioma();
    }, [numIdioma, ref]);
    console.log(documentos);
    const downloadFile = async (id, nombre) => {
        await downloadFilePropertie(id, nombre);
    }

    return (
        <>
            {SEO({ title: textos?.titulo, description: textos?.descripcion?.replace(/[\^*~'\\]/gi, ' '), type: 'article', image: fotos?.[0]?.url, ruta: "/obra-nueva/" + ref, refPropiedad: ref, precioPropiedad: propiedad?.precioalq || propiedad?.precioinmo })}
            <Menu />
            {no_existe.status == 404 ?
                <>
                    <div className='text-center my-5 display-6' style={{ fontWeight: 'lighter' }}>
                        <p className='not-found'>404 </p>
                        <p>Propiedad no encontrada</p>
                    </div>
                </>
                :
                <>
                    <div className="container-obra container-fluid d-flex" style={{ alignItems: "center", position: "relative" }}>
                        <div className="opacidad-obra"></div>
                        <div className="container" style={{ zIndex: "100" }}>
                            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "800px" }}>
                                <h1 className="display-4 text-white mb-1 text-white" >{textos?.titulo}</h1>
                            </div>
                        </div>
                    </div>
                    <Linea1 />
                    <div className="container-xxl pd-5 pdb-5">
                        <div className="container">
                            <div className="text-center  mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp" }}>
                                <p className='fst-italic' style={{ textAlign: "justify" }}>
                                    <b>{propiedad?.referencia}</b>{" "}
                                    {textos?.descripcion?.split('~').map((fragment, index) => (
                                        <React.Fragment key={index}>
                                            {fragment}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </p>

                                <p className='text-uppercase'>{propiedad?.tipo} <b>{propiedad?.precioinmo == 0 ? `${t('propiedad.caracteristicas.aconsultar')}` : "Desde " + parseInt(propiedad?.precioinmo).toLocaleString('es-ES') + "€"}</b></p>
                            </div>
                        </div>
                    </div>
                    {/* PROYECTO */}
                    <div className="container-fluid p-0">
                        <div className="container-fluid px-0">
                            <div className="row g-0 w-100">
                                <div className="col-lg-6 col-md-6 wow fadeInUp order-md-1 order-sm-1 order-xs-1" data-wow-delay="0.1s">
                                    <div id="cimg" className="bg-imagen-aux-1" style={{ height: "100%" }} >

                                        <img src={fotos[1]?.url} className="responsive-image" style={{ height: "100%" }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 bg-color-gray d-flex flex-column justify-content-center order-md-2 order-sm-2 order-xs-2">
                                    <div className="text-start">
                                        <h2 className="mb-4">{t("obra.proyecto")}</h2>
                                        <p>
                                            {textos && textos?.proyecto === undefined ? "NO HAY TEXTO" : textos?.proyecto}
                                        </p>
                                        {documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'proyecto').length === 0 ? (
                                            <></>
                                        ) : (
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-borde py-3 px-5"
                                                    style={{ width: '250px' }}
                                                    onClick={() => {
                                                        downloadFile(
                                                            documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'proyecto')[0].id,
                                                            `${documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'proyecto')[0].nombre}`
                                                        );
                                                    }}
                                                >
                                                    {t("obra.verProyecto")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PLANOS */}
                    <div className="container-fluid p-0">
                        <div className="container-fluid px-0">
                            <div className="row g-0 w-100">
                                <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 d-flex flex-column justify-content-center order-md-1 order-sm-2 order-xs-2">
                                    <div className="text-start">
                                        <h2 className="mb-4">{t("obra.entorno")}</h2>
                                        <p>
                                            {textos?.entorno == undefined ? "NO HAY TEXTO" : textos?.entorno}
                                        </p>
                                        {documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'entorno').length === 0 ? (
                                            <></>
                                        ) : (
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-borde py-3 px-5"
                                                    style={{ width: '250px' }}
                                                    onClick={() => {
                                                        downloadFile(
                                                            documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'entorno')[0].id,
                                                            `${documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'entorno')[0].nombre}`
                                                        );
                                                    }}
                                                >
                                                    {t("obra.verPlanos")}
                                                </button>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp order-md-2 order-sm-1 order-xs-1" data-wow-delay="0.1s">
                                    <div id="cimg" className="bg-imagen-aux-1" style={{ height: "100%" }}>
                                        <img src={fotos[2]?.url} className="responsive-image" style={{ height: "100%" }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* CALIDADES */}
                    <div className="container-fluid p-0">
                        <div className="container-fluid px-0">
                            <div className="row g-0 w-100">
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div id="cimg" className="bg-imagen-aux-1" style={{ height: "100%" }}>
                                        <img src={fotos[3]?.url} className="responsive-image" style={{ height: "100%" }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 bg-color-gray d-flex flex-column justify-content-center">
                                    <div className="text-start">
                                        <h2 className="mb-4">{t("obra.disenyo")}</h2>
                                        <p>
                                            {textos?.disenyo == undefined ? "NO HAY TEXTO" : textos?.disenyo}
                                        </p>
                                        {documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'disenyo').length === 0 ? (
                                            <></>
                                        ) : (
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-borde py-3 px-5"
                                                    style={{ width: '250px' }}
                                                    onClick={() => {
                                                        downloadFile(
                                                            documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'disenyo')[0].id,
                                                            `${documentos.filter(doc => doc.id_idioma === numIdioma && doc.seccion == 'disenyo')[0].nombre}`
                                                        );
                                                    }}
                                                >
                                                    {t("obra.memoria")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0 d-flex" style={{ alignItems: "center" }}>
                        <div className="container-fluid px-0">
                            <div className="row g-0 w-100">
                                <div className="col-lg-6 col-md-6 wow fadeInUp px-5 py-5 bg-color-gray d-flex flex-column justify-content-center">
                                    <div className="text-start">
                                        <h2 className="mb-4">{t("obra.ubicacion")}</h2>
                                        <p>
                                            {textos?.ubicacion === undefined ? "NO HAY TEXTO" : textos?.ubicacion}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                                        <Maps coordenadas={[{ lat: parseFloat(propiedad?.latitud), lng: parseFloat(propiedad.longitud) }]} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="container-fluid p-0 d-flex" style={{ alignItems: "center" }}>
                        <div className="container p-0">
                            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                                {fotos.length > 0 ?
                                    <Gallery images={fotosGaleria.sort()} galleryRef={galleryRef} gal={0} />
                                    :
                                    <img src={sinImagen} width={"700hv"} />
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <Footer />
            <style jsx="true">{
                `
            .container-obra {
                background-image: url(${`'${fotos[0]?.url}'`});
                min-height: 600px!important;
                background-attachment: fixed!important;
                background-position: center!important;
                background-repeat: no-repeat!important;
                background-size: cover!important;
                width: 100%!important;
              }
            .opacidad-obra {
                background-color: black;
                opacity: 0.3;
                height: 100%;
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
            }
            .responsive-image {
                width: 100%;
                max-height: auto;
                object-fit: cover;
            }
            .image-gallery {
                max-width: 100%;
            }
          
            .image-gallery.fullscreen {
                background: var(--secondary)!important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
            }
            .image-gallery-fullscreen {
                min-height: 60.2em!important;
                max-height: 60.2em!important;
            }
            :not(:root):fullscreen::backdrop {
                position: fixed;
                inset: 0px;
                background: #384043;
            }
            `
            }

            </style>
        </>


    );

}

export default withRouter(FichaObranueva);
import { useState } from "react";
import { listPromociones } from "../service";

function usePromociones(){
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: []
    });
    const[loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data.map((row) => ({
            id: row.id,
            nombre:row.nombre,
            url:row.url,
            img:row.img,
            latitud:row.latitud,
            longitud:row.longitud,
        }));
    }
    const fetchData = async({offset, limit, filtros}) => {
        setLimite(limit);
        const payload = {
            params: {page: offset, limit},
            filtros
        };
        try {
            setLoading(true);
            let response = await listPromociones(payload);   
            const { meta, data } = response;
            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        limite,
    };
}
export default usePromociones;


import axios from "axios";

const urlPath = "promociones";

export const listPromociones = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });
        return response.data;
    } catch (error) {
       
        console.log(error);
    }
}
import React, { useEffect, useState } from "react";
import LogoBlanco from './../images/Logo Lae Homes blanco.png'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate,useParams } from "react-router";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    const { t } = useTranslation("global");
    const [an, setAn] = useState('');
    //const DOMAIN = window.location.origin;
    const route = useLocation();
    // const navigate = useNavigate();
    // const params = useParams();
    
    useEffect(()=>{ 
        const scrollTo = (dest) => {
            // console.log(dest)
            // console.log(route.pathname)
            let element = document.getElementById(dest);
            element.scrollIntoView({behavior:'smooth', block:'end'});   
        };
        if(route.pathname === "/firma"){
            // console.log(route.hash)
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }
        if(route.pathname === "/servicios"){
            // console.log(route.hash)
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }
        if(route.pathname === "/obra-nueva"){
            // console.log(route.hash)
            // setTimeout(() => scrollTo(0), 100);
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }
        if(route.pathname === "/obra-nueva/:id"){
            // console.log(route.hash)
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }
        
    },[an]);
    return ( 
        <>
            <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top">
                <i className="bi bi-arrow-up"></i>
            </a>
            <div className="container-fluid bg-secondary footer py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6 text-md-start text-center">
                            <img src={LogoBlanco} alt="LAEHOMES" width='175px' />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 text-sm-start text-center">
                            <h6 className="text-white mb-4 text-sm-start text-center no-wrap" >{t("footer.firma")} <span className="linea-aux"></span></h6>
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/firma#concepto" onClick={()=>setAn('concepto')}>{t("footer.historia")}</Link>
                            {/* <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/firma#concepto" onClick={()=>setAn('conceptoo')}>{t("footer.concepto")}</Link> */}
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/firma#equipo" onClick={()=>setAn('equipo')}>{t("footer.equipo")}</Link>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 text-sm-start text-center">
                            <h6 className="text-white mb-4 text-sm-start text-center no-wrap" >{t("footer.propiedades")} <span className="linea-aux"></span></h6>
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/obra-nueva" onClick={()=>setAn('on')}>{t("footer.obraNueva")}</Link>
                            {/* <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/propiedades-exclusivas">{t("footer.propiedadesExclusivas")}</Link> */}
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/propiedades">{t("footer.propiedadesLink")}</Link>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 text-sm-start text-center">
                            <h6 className="text-white mb-4 text-sm-start text-center no-wrap" >{t("footer.servicios")} <span className="linea-aux"></span></h6>
                            {/* <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/servicios#agente" onClick={()=>setAn('agentes')}>{t("footer.agentes")}</Link> */}
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/servicios#compra" onClick={()=>setAn('compra')}>{t("footer.compra")}</Link>
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/servicios#vende" onClick={()=>setAn('vende')}>{t("footer.vende")}</Link>
                            <Link className="btn btn-footer-link text-sm-start text-center d-block" to="/servicios#aaff" onClick={()=>setAn('aaff')}>{t("footer.aaff")}</Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 text-sm-start text-center">
                            <h6 className="text-white mb-4 text-sm-start text-center no-wrap" >{t("footer.contacto")} <span className="linea-aux"></span></h6>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-sm-start text-center no-wrap">
                                    <Link to="/contacto" className="btn btn-footer-link text-sm-start text-center d-block">{t("footer.contactoT")}</Link>
                                    <Link to="/oficinas" className="btn btn-footer-link text-sm-start text-center d-block">{t("footer.nOficinas")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright py-4" style={{fontSize: '11px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="fw-medium text-dark mx-2" href="1.html">{new Date().getFullYear()} LAE HOMES</a>
                            <Link className="fw-medium text-dark mx-2" to="/aviso-legal" rel="noreferrer" target={"_blank"}>{t("footer.avisoLegal")}</Link>
                            <Link className="fw-medium text-dark mx-2" to="/politica-privacidad" rel="noreferrer" target={"_blank"}>{t("footer.privacidad")}</Link>
                            <Link className="fw-medium text-dark mx-2" to="/politica-cookies" rel="noreferrer" target={"_blank"}>{t("footer.cookies")}</Link>

                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <a className="fw-medium text-dark mx-2" rel="noreferrer" target={"_blank"} href="https://www.instagram.com/laehomes/"><i className="fa-brands fa-instagram"></i></a>
                            <a className="fw-medium text-dark mx-2" rel="noreferrer" target={"_blank"} href="https://www.facebook.com/laehomes/"><i className="fa-brands fa-facebook-f"></i></a>
                            <a className="fw-medium text-dark mx-2" rel="noreferrer" target={"_blank"} href="https://www.linkedin.com/company/lae-homes/?viewAsMember=true"><i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Footer;
import Footer from "../static/footer";
import Menu from "../static/menu";
import Img from "./../images/TextosLegales/Banner-superior-textos-legales.jpg"
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import SEO from "../utilities/SEO";

const AvisoLegal = () => {
    const { t } = useTranslation("global");
    return ( 
        <>
            {SEO({title: "Aviso Legal", type: 'website', ruta: "/aviso-legal"})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Aviso Legal</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl pd-5 pdb-5">
                <div className="container">

                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <p>
                            En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), la empresa  le informa que es titular del sitio web. De acuerdo con la exigencia del artículo 10 de la citada Ley, informa de los siguientes datos: 
                        </p>
                        <p>El titular de este sitio web es:</p>
                        <table className="table text-center ">
                            <tbody>
                                <tr>
                                    <td>Nombre de la empresa</td>
                                    <td>Lae Consulting Normativas, S.L.</td>
                                </tr>
                                <tr>
                                    <td>NIF</td>
                                    <td>B75010686</td>
                                </tr>
                                <tr>
                                    <td>Dirección</td>
                                    <td>C/ Juan F. Gilisagasti, 2, 20018, San Sebastián, Guipúzcoa</td>
                                </tr>
                                <tr>
                                    <td>Inscripción en el registro mercantil</td>
                                    <td>Registro Mercantil de Gipuzkoa: Tomo: 2451 Libro: 0 Folio: 29 Hoja: SS-32063, inscripción: 6ª</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>USUARIOS</u></h4>
                        <p>
                            El acceso y/o uso de este portal le atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>USO DEL PORTAL</u></h4>
                        <p>
                        La página web proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a Lae Consulting Normativas, S.L. o a sus licenciantes a los que el USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos.
                        </p>
                        <p>
                            En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma. El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios que Lae Consulting Normativas, S.L. ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para: 
                        </p>
                        <ul>
                            <li>Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público</li>
                            <li>Difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos</li>
                            <li>Provocar daños en los sistemas físicos y lógicos de Lae Consulting Normativas, S.L., de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados;</li>
                            <li>Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar manipular sus mensajes. Lae Consulting Normativas, S.L. se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación. En cualquier caso, Lae Consulting Normativas, S.L. no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación.</li>
                        </ul>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>PROPIEDAD INTELECTUAL E INDUSTRIAL</u></h4>
                        <p>
                        Lae Consulting Normativas, S.L. será titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).
                        </p>
                        <p>
                        Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Lae Consulting Normativas, S.L. El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Lae Consulting Normativas, S.L. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el las páginas de Lae Consulting Normativas, S.L.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</u></h4>
                        <p>
                            Lae Consulting Normativas, S.L. será titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>MODIFICACIONES</u></h4>
                        <p>
                            Lae Consulting Normativas, S.L. se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su portal.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>ENLACES</u></h4>
                        <p>
                            En el caso de que en la página web se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, Lae Consulting Normativas, S.L. no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso Lae Consulting Normativas, S.L. asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.
                        </p>
                        <p>
                            Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>DERECHO DE EXCLUSIÓN</u></h4>
                        <p>
                            Lae Consulting Normativas, S.L. se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>GENERALIDADES</u></h4>
                        <p>
                            Lae Consulting Normativas, S.L. perseguirá el incumplimiento de las presentes condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</u></h4>
                        <p>
                            Lae Consulting Normativas, S.L. podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen.
                        </p>
                        <p>
                            La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4"><u>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</u></h4>
                        <p>
                            La relación entre Lae Consulting Normativas, S.L. y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad anteriormente indicada.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }                
            `}</style>
        </>
    );
}
 
export default AvisoLegal;
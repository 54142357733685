import React, { useState, useEffect, useContext} from 'react';
// import Logo from './../images/Logo Lae Homes color.png'
import Logo from './../images/logo_laehomes_color.png'
import Simbolo from './../images/favicon_laehomes.svg'
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { abrirMenu, cerrarMenu } from '../providers/functions';
import { useTranslation } from "react-i18next";
import { withRouter } from '../providers/withRouter';
import { ModalContext } from '../providers/ModalContext';
import $ from 'jquery'

const Menu = (props) => {
    const { isModalVisible } = useContext(ModalContext);
    const [colorP, setColorP] = useState('');
    const [colorF, setColorF] = useState('');
    const [colorS, setColorS] = useState('');
    const [colorC, setColorC] = useState('');
    const { t, i18n } = useTranslation("global");
    const [idioma, setIdioma] = useState()
    const [fmenu, setFmenu] = useState('');
    const route = useLocation();
    const idiomaList = ['ES', 'EN'/*, 'FR'*/];
    function handleChangeLanguage(evt) {
        const lang = evt.target.value
        setIdioma(lang)
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        window.location.reload();
    }

    const [show, setShow] = useState(false);
    const handleChange = () => {
        setShow(!show);
        cerrarMenu();
    }

    useEffect(()=>{
       
        
        if (isModalVisible == true) {
           
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
       
        if(props.location.pathname === '/propiedades' || props.location.pathname === '/propiedades-exclusivas'){
            $('#navbarDropdownP').addClass('active');
        }else{
            $('#navbarDropdownP').removeClass('active');
        }
        if(props.location.pathname === '/contacto' || props.location.pathname === '/oficinas'){
            $('#navbarDropdownC').addClass('active');
        }else{
            $('#navbarDropdownC').removeClass('active');
        }
        if(props.location.pathname === '/firma' && props.location.hash === '#historia'){
            $('#navbarDropdownF').addClass('active');
            $('.dm a.ddfh').addClass('active');
            $('.dm a.ddfc').removeClass('active');
            $('.dm a.ddfe').removeClass('active');
        }else if(props.location.pathname === '/firma' && props.location.hash === '#concepto'){
            $('#navbarDropdownF').addClass('active');
            $('.dm a.ddfc').addClass('active');
            $('.dm a.ddfh').removeClass('active');
            $('.dm a.ddfe').removeClass('active');
        }else if(props.location.pathname === '/firma' && props.location.hash === '#equipo'){
            $('#navbarDropdownF').addClass('active');
            $('.dm a.ddfe').addClass('active');
            $('.dm a.ddfh').removeClass('active');
            $('.dm a.ddfc').removeClass('active');
        }else{
            $('.dm a.ddfh').removeClass('active');
            $('.dm a.ddfc').removeClass('active');
            $('.dm a.ddfe').removeClass('active');
            $('#navbarDropdownF').removeClass('active');
        }
        if(props.location.pathname === '/servicios' && props.location.hash === '#compra'){
            $('#navbarDropdownS').addClass('active');
            $('.dm a.ddc').addClass('active');
            $('.dm a.ddv').removeClass('active');
            $('.dm a.dda').removeClass('active');
        }else if(props.location.pathname === '/servicios' && props.location.hash === '#vende'){
            $('#navbarDropdownS').addClass('active');
            $('.dm a.ddv').addClass('active');
            $('.dm a.ddc').removeClass('active');
            $('.dm a.dda').removeClass('active');
        }else if(props.location.pathname === '/servicios' && props.location.hash === '#aaff'){
            $('#navbarDropdownS').addClass('active');
            $('.dm a.dda').addClass('active');
            $('.dm a.ddc').removeClass('active');
            $('.dm a.ddv').removeClass('active');
        }else{
            $('.dm a.ddc').removeClass('active');
            $('.dm a.ddv').removeClass('active');
            $('.dm a.dda').removeClass('active');
            $('#navbarDropdownS').removeClass('active');
        }

        $("ul li").click(function () {
            if ($(this).hasClass('exc')) {
                // No hacer nada si es la clase excepción
                return;
              }
              // Eliminar la clase del otro div
              $('#sidebar').removeClass('active');
        })
      
        window.innerWidth > 992 && (props.location.pathname === '/propiedades' || props.location.pathname === '/propiedades-exclusivas') ? setColorP('#b8a163') : $(this).scrollTop() > 150 ? setColorP('#3D4245') : setColorP('#fff')
        window.innerWidth > 992 && props.location.pathname === '/firma' ? setColorF('#b8a163') : $(this).scrollTop() > 150 ? setColorF('#3D4245') : setColorF('#fff')
        window.innerWidth > 992 && props.location.pathname === '/servicios' ? setColorS('#b8a163') : $(this).scrollTop() > 150 ? setColorS('#3D4245') : setColorS('#fff')
        window.innerWidth > 992 && (props.location.pathname === '/contacto' || props.location.pathname === '/oficinas') ? setColorC('#b8a163') : $(this).scrollTop() > 150 ? setColorC('#3D4245') : setColorC('#fff')
        $(window).scroll(function () {
            // props.location.hash === '#compra' || props.location.hash === '#vende' || props.location.hash === '#aaff' ? setColor('red') : setColor('green')
        })
        
        //si tiene ancla va a el al cargar
        const anchorElement = document.getElementById(props.location.hash.substring(1));
        if (anchorElement) {
            anchorElement.scrollIntoView({ behavior: 'smooth' });
            console.log(anchorElement)
        }
        if(route.hash === ''){
            window.scrollTo(0, 0);
        }

      
        setIdioma(localStorage.getItem('language'))
        i18n.changeLanguage(localStorage.getItem('language'));
    },[isModalVisible,fmenu]);
    return ( 
        <>
            <div className="container-fluid d-none d-lg-flex bg-white">
                <div id="menu-top" className="container py-3">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="ms-3 d-flex">
                                <a className="btn btn-sm-square color-primary rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.instagram.com/laehomes/">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a className="btn btn-sm-square color-primary rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.facebook.com/laehomes/">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a className="btn btn-sm-square color-primary rounded-circle ms-2" rel="noreferrer" target={'_blank'} href="https://www.linkedin.com/company/lae-homes/?viewAsMember=true">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                        <div className="m-center">
                            <Link to="/inicio">
                                <h2 className="text-white fw-bold m-0">
                                    <img src={Logo} id="logo" width="150" alt="LaeHomes" />
                                </h2>
                            </Link> 
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="ms-3 d-flex">
                                <div style={{"width":"100px"}}></div>
                                <select className="navbar-select" name="idiomas" onChange={handleChangeLanguage} value={idioma}>
                                    {idiomaList.map((idi, i )=> {
                                        return (
                                            <option key={i} value={idi.toLocaleLowerCase()}>{idi}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SIDEBAR */}
            <nav id="sidebar">
                <div className="sidebar-header">
                    <img src={Simbolo} width="80" alt="LaeHomes"/>
                    <i id="btn-close" className="fas fa-times btn-close" onClick={()=> cerrarMenu()}></i>
                </div>

                <ul className="list-unstyled components">
                    <li>
                        <NavLink to="/inicio" onClick={()=>setFmenu('inicio')} className="nav-item nav-link">{t("menu.inicio")}</NavLink>
                    </li>
                    {/* FIRMA */}
                    <li className='exc'>
                        <div className='serviceItem'>
                            <a href="#pageSubmenuF" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">{t("menu.firma")}</a>
                        </div>
                        <ul className="collapse list-unstyled" id="pageSubmenuF">
                            <li><NavLink className="dropdown-item ddfh ps" style={{ fontSize: '14px'}} onClick={()=>setFmenu('historia')} to="/firma#historia">{t("menu.firmaSubmenu1")}</NavLink></li>
                            <li><NavLink className="dropdown-item ddfc ps" style={{ fontSize: '14px'}} onClick={()=>setFmenu('concepto')} to="/firma#concepto">{t("menu.firmaSubmenu2")}</NavLink></li>
                            <li><NavLink className="dropdown-item ddfe ps" style={{ fontSize: '14px'}} onClick={()=>setFmenu('equipo')} to="/firma#equipo">{t("menu.firmaSubmenu3")}</NavLink></li>
                        </ul>
                    </li>
                    {/* --- FIRMA */}
                    <li>
                        <NavLink to="/propiedades" onClick={()=>setFmenu('props')} className="nav-item nav-link">{t("menu.propiedades")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/obra-nueva" onClick={()=>setFmenu('ob_n')} className="nav-item nav-link">{t("menu.obraNueva")}</NavLink>
                    </li>
                    <li>
                        <NavLink to={'https://betterplaceapp.com/valorar/lae-homes/u/38285?locale=' + i18n.language || 'es'} hrefLang={i18n.language || 'es'} className="nav-item nav-link">{t("menu.valorador")}</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/promociones" onClick={()=>setFmenu('promo')} className="nav-item nav-link">{t("menu.promocion")}</NavLink>
                    </li> */}
                    <li className='exc'>
                        <div className='serviceItem'>
                            {/* <NavLink to="/servicios" onClick={()=>setFmenu('servs')} className="nav-item nav-link">{t("menu.servicios")}</NavLink> */}
                            <a href="#pageSubmenuS" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">{t("menu.servicios")}</a>
                        </div>
                        <ul className="collapse list-unstyled" id="pageSubmenuS">
                            <li>
                                <NavLink className="dropdown-item ddc" style={{ fontSize: '14px'}} onClick={()=>setFmenu('compra')} to="/servicios#compra">
                                    {t("menu.serviciosSubmenu1")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="dropdown-item ddv" style={{ fontSize: '14px'}} onClick={()=>setFmenu('vcont')} to="/servicios#vende">
                                    {t("menu.serviciosSubmenu2")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="dropdown-item dda" style={{ fontSize: '14px'}} onClick={()=>setFmenu('aaff')} to="/servicios#aaff">
                                    {t("menu.serviciosSubmenu3")}
                                </NavLink>
                            </li>
                            
                        </ul>
                    </li>
                    <li>
                        <NavLink to="/blog" className="nav-item nav-link">{t("menu.trends")}</NavLink>
                    </li>
                    <li className='exc'>
                        <div className='serviceItem'>
                            <a href="#pageSubmenuC" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">{t("menu.contacto")}</a>
                        </div>
                        <ul className="collapse list-unstyled" id="pageSubmenuC">
                            <li><NavLink className="dropdown-item ps" to="/contacto" onClick={()=>setFmenu('contacto')} >{t("menu.contactoSubmenu1")}</NavLink></li>
                            <li><NavLink className="dropdown-item ps" to="/oficinas" onClick={()=>setFmenu('oficinas')}>{t("menu.contactoSubmenu2")}</NavLink></li>
                        </ul>
                    </li>
                    
                    <li className='exc'>
                        <div className="align-items-center">
                            <div className="" style={{"color":"white"}}>
                                <div style={{"width":"100px"}}></div>
                                <select className="navbar-select idioma-side" name="idiomas" onChange={handleChangeLanguage} value={idioma}>
                                    {idiomaList.map((idi, i )=> {
                                        return (
                                            <option className='option-side' key={i} value={idi.toLocaleLowerCase()}>{idi}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </li>
                    {/* <li>
                        <button type="button" className="btn-area" onClick={() => handleChange()}>AREA VIP</button>
                    </li> */}
                </ul>
            </nav>
            {/* MENU GRIS  */}
            <div id='menuPrincipal' className="container-fluid bg-color-secondary sticky-top menu-bottom mi">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-lg-0">
                        <Link to="/" className="navbar-brand d-lg-none">
                            <h1 className="fw-bold m-0"><img src={Logo} width="150" alt="LaeHomes"/></h1>
                        </Link>
                        <button type="button" id="sidebarCollapse" className="navbar-toggler me-0" data-bs-toggle="collapse" onClick={() => {abrirMenu()}}
                            data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div id="sticky-logo" className="m-center sticky">
                            <a href="/">
                                <h2 className="text-white fw-bold m-0">
                                    <img src={Simbolo} id="simbolo" width="50" alt="LaeHomes" />
                                </h2>
                            </a> 
                        </div>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav m-center align-items-center">
                                <NavLink to="/" className="nav-item nav-link">{t("menu.inicio")}</NavLink>
                                <div className="dropdown">
                                    {<NavLink id="navbarDropdownF" className="nav-item nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t("menu.firma")}
                                    </NavLink>}
                                    <ul className="dropdown-menu dm">
                                        <li><NavLink className="dropdown-item ddfh" style={{ fontSize: '14px'}} onClick={()=>setFmenu('historia')} to="/firma#historia">{t("menu.firmaSubmenu1")}</NavLink></li>
                                        <li><NavLink className="dropdown-item ddfc" style={{ fontSize: '14px'}} onClick={()=>setFmenu('concepto')} to="/firma#concepto">{t("menu.firmaSubmenu2")}</NavLink></li>
                                        <li><NavLink className="dropdown-item ddfe" style={{ fontSize: '14px'}} onClick={()=>setFmenu('equipo')} to="/firma#equipo">{t("menu.firmaSubmenu3")}</NavLink></li>
                                    </ul>

                                </div>
                                {/* ENLACE PARA MOSTRAR EL SUBMENU DE PROPIEDADES AL HACER HOVER */}
                                <NavLink to="/propiedades"className="nav-item nav-link"> {t("menu.propiedades")}</NavLink>
                                <NavLink to="/obra-nueva" className="nav-item nav-link">{t("menu.obraNueva")}</NavLink>
                                <NavLink to={'https://betterplaceapp.com/valorar/lae-homes/u/38285?locale=' + i18n.language || 'es'} hrefLang={i18n.language || 'es'} className="nav-item nav-link">{t("menu.valorador")}</NavLink>
                                {/* <NavLink to="/promociones" className="nav-item nav-link"> {t("menu.promocion")}</NavLink> */}
                           
                                <div className="dropdown">
                                    {/* <NavLink to="/servicios" className="nav-item nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{t("menu.servicios")}</NavLink> */}
                                    {<NavLink id="navbarDropdownS" className="nav-item nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t("menu.servicios")}
                                    </NavLink>}
                                    <ul className="dropdown-menu dm">
                                        <li>
                                            <NavLink className="dropdown-item ddc" style={{ fontSize: '14px'}} onClick={()=>setFmenu('compra')} to="/servicios#compra">
                                                {t("menu.serviciosSubmenu1")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item ddv" style={{ fontSize: '14px'}} onClick={()=>setFmenu('vcont')} to="/servicios#vende">
                                                {t("menu.serviciosSubmenu2")}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item dda" style={{ fontSize: '14px'}} onClick={()=>setFmenu('aaff')} to="/servicios#aaff">
                                                {t("menu.serviciosSubmenu3")}
                                            </NavLink>
                                        </li>
                                        
                                    </ul>

                                </div>
                                <div  className="dropdown">
                                    {/* <NavLink to="/servicios" className="nav-item nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{t("menu.servicios")}</NavLink> */}
                                    {<NavLink id="navbarDropdownC" className="nav-item nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {t("menu.contacto")}
                                    </NavLink>}
                                    <ul className="dropdown-menu dm">
                                        <NavLink className="dropdown-item" style={{ fontSize: '14px'}} to="/contacto">{t("menu.contactoSubmenu1")}</NavLink>
                                        <NavLink className="dropdown-item" style={{ fontSize: '14px'}} to="/oficinas">{t("menu.contactoSubmenu2")}</NavLink>
                                    </ul>

                                </div>
                                <NavLink to="/blog" className="nav-item nav-link">{t("menu.trends")}</NavLink>
                                {show}
                            </div>
                        </div>
                        {/* CUADNO SE HACE SCROLL */}
                        <div id="sticky-area" className="d-flex align-items-center sticky">
                            <div className="ms-3 d-flex">
                                <div style={{"width":"100px"}}></div>
                                {/* <button type="button" className="btn-area" onClick={() => handleChange()}><i className="fa fa-star"></i>Area Vip</button> */}
                                <select className="navbar-select" name="idiomas" onChange={handleChangeLanguage} value={idioma}>
                                    {idiomaList.map((idi, i )=> {
                                        return (
                                            <option key={i} value={idi.toLocaleLowerCase()}>{idi}</option>
                                        )
                                    })}
                                    {/* <option value="fr">FR</option> */}
                                </select>
                            </div>
                        </div>
                    </nav>
                   
                </div>
            </div>
            <style jsx="true">{`
                #menuPrincipal{
                    padding-right: 0px !important;
                    margin-right: 0px !important;
                }
                #navbarDropdownS.dropdown-toggle::after{
                    color: ${colorS} 
                }
                #navbarDropdownP.dropdown-toggle::after{
                    color: ${colorP} 
                }
                #navbarDropdownF.dropdown-toggle::after{
                    color: ${colorF} 
                }
                #navbarDropdownC.dropdown-toggle::after{
                    color: ${colorC} 
                }
                #navbarDropdownP:hover, #navbarDropdownS:hover, #navbarDropdownF:hover, #navbarDropdownC:hover, .navbar .dropdown-toggle:hover::after{
                    color: var(--primaryHover) !important;
                }
                .idioma-side {
                    color: white;
                    padding: 10px;
                    font-size: 1.1em;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
                }
                .option-side {
                    color:black;
                }
            `}</style>
        </>
    );
}
 
export default withRouter(Menu);
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { withRouter } from '../providers/withRouter';
import Footer from '../static/footer';
import Linea1 from '../static/linea1';
import Linea3 from '../static/linea3';
import Menu from '../static/menu';
import Img1 from "./../images/Trends/Banner-superior-trends.jpg"
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { getArticle } from '../providers/api'; 
import SEO from '../utilities/SEO';

const Trend = () => {
    
    const data = [
        // {id: "1", fecha: '10 Marzo 2023', img: 'https://3.bp.blogspot.com/-kRvaP5I4GXA/XJ9U4U7WCOI/AAAAAAAADXY/6ntBe3dxGlYLLZq9OxDGf4urEoejuBnvQCLcBGAs/w400/199.jpeg', titulo: 'El lugar perfecto 1', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "2", fecha: '11 Marzo 2023', img: 'https://images.pexels.com/photos/7319307/pexels-photo-7319307.jpeg', titulo: 'El lugar perfecto 2', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "3", fecha: '13 Marzo 2023', img: 'https://1.bp.blogspot.com/-nMSa4Nn7Ptc/XYnmptL7xaI/AAAAAAAAD6Y/4qZ7nfvQIbIx7UFSPYEP0l95WHEVjM6fwCNcBGAsYHQ/w400/168.jpg', titulo: 'El lugar perfecto 3', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "4", fecha: '14 Marzo 2023', img: 'https://4.bp.blogspot.com/-R-W4st0RZSI/WO-0p3MJOjI/AAAAAAAAAZw/78ifeoiqCWgk3g5rn06FzJMtDwTON6pcQCLcB/s1600/img-1-380x475.jpg', titulo: 'El lugar perfecto 4', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "5", fecha: '15 Marzo 2023', img: 'https://4.bp.blogspot.com/-_VUr4RH5TeA/WO-0zvhepeI/AAAAAAAAAaE/022bDzNgjxw7qRlhU23KCi2Mmte-moKwQCLcB/s1600/14-380x253.jpg', titulo: 'El lugar perfecto 5', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "6", fecha: '16 Marzo 2023', img: 'https://1.bp.blogspot.com/-9XnOoVlReAw/WO-1PTTNOzI/AAAAAAAAAak/QiZUHUYUE58mTl9B1tQMGVaigVr1FLF5gCLcB/s1600/6-380x380.jpg', titulo: 'El lugar perfecto 6', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "7", fecha: '17 Marzo 2023', img: 'https://3.bp.blogspot.com/-UZ6aTxCipsA/WO-0zNSI2eI/AAAAAAAAAZ4/kJyIJIwYjbACRAkCk8gMSBPBOwOjLmbDACPcB/s1600/11-380x253.jpg', titulo: 'El lugar perfecto 7', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "8", fecha: '18 Marzo 2023', img: 'https://2.bp.blogspot.com/-lO-_sTpNVXk/WO-004bHfdI/AAAAAAAAAaU/SK4XAHnHny0hH79ClImZNgZGprjRxfulACPcB/s1600/8-380x475.jpg', titulo: 'El lugar perfecto 8', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "9", fecha: '18 Marzo 2023', img: 'https://1.bp.blogspot.com/-vIKm5p_3OeA/WO-0zmwqV-I/AAAAAAAAAaA/f_IDerUTiL4mu2RVv68TwfhlGvT8bbgyACPcB/s1600/13-380x253.jpg', titulo: 'El lugar perfecto 9', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "10", fecha: '19 Marzo 2023', img:'https://1.bp.blogspot.com/-nxE5vIdt4Us/XTakuNcqhII/AAAAAAAADyY/EgJ7hpOLs10_h2k9yRNsFET1hKzG1nhZwCLcBGAs/w400/img-334.jpg', titulo: 'El lugar perfecto 10', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "11", fecha: '20 Marzo 2023', img:'https://2.bp.blogspot.com/-2lfoUvrFDk0/XJ9TgiebqyI/AAAAAAAADWw/7onsCw6RC0A2X1DwtoCiQAkNk26Q776aQCLcBGAs/w400/206.jpeg', titulo: 'El lugar perfecto 11', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "12", fecha: '21 Marzo 2023', img:'https://1.bp.blogspot.com/-xb8TF4SzdE8/XdUqK3UQENI/AAAAAAAAEE4/dbB6pmxuC08nzF67hizdg2eposOFteNlwCNcBGAsYHQ/w400/6.jpg', titulo: 'El lugar perfecto 12', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "13", fecha: '22 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 13', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "14", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-IdIUpQDP35I/XJ9VJFXG7MI/AAAAAAAADXg/8rboDT-FDxAyg2CygF120kh5_-lxVuh6gCLcBGAs/w400/202.jpeg', titulo: 'El lugar perfecto 14', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "15", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-OlCKjMigKQ0/XYdfUZqv-KI/AAAAAAAAD6A/anwZ57Ppsac_qUi1M7d0DB4uVxTgRCgPgCNcBGAsYHQ/w400/150.jpg', titulo: 'El lugar perfecto 15', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "16", fecha: '24 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 16', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
    ];
    const { id } = useParams();

    console.log(id);

    const [noticia, setNoticia] = useState({})
    const [noticias, setNoticias] = useState([])
    const [numItem, setNumItem] = useState(3)
    const [article, setArticle] = useState({});
    const [server, setServer] = useState({});

    const fetchArticle = async () => {
        try {
            const response = await getArticle(id);
            setArticle(response.data.data);
            const { url } = response.config;
            // console.log(url);
            const match = url.match(/^https?:\/\/[^/]+/); // Buscar la parte inicial de la URL
            // const baseUrl = match ? match[0] : ""; // Obtener la URL base si se encontró una coincidencia
            setServer( match ? match[0] : "");
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchArticle();
        // let categoria = ''
        // data.forEach( d => {
        //     if(d.id === props.params.id){
        //         categoria = d.categoria
        //         setNoticia(d)
        //     }
        // })

        // let noticias = []
        // data.forEach( d => {
        //     if(d.categoria === categoria && d.id!== props.params.id){
        //         //console.log(d)
        //         noticias.push(d)
        //     }
        // })
        // setNoticias(noticias)

        // if (window.innerWidth <= 768) {
        //     setNumItem(1)
        // }else{
        //     setNumItem(3)
        // }
    },[]);
    console.log(article);
    const articleImg = `${server}${article.img}`
    return ( 
        <>
        {SEO({title: article.title, description: '', type: 'article', img: articleImg, ruta: "/blog/" + article.id})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Blog</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl py-5">
                <div className="container py-5 mt-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="row g-4">
                        <div className="mx-auto" style={{ /* height: 'fit-content', */ display: 'inline-block', maxWidth: "800px" }}>
                            <div className="service-item position-relative h-100">
                                <div className="trends-text">
                                    <div className="bg-light mx-auto">
                                        {/* <img className="img-fluid w-100" src={require(`./../images/${d.img}`)} alt="Imagen"/> */}
                                        <img className="img-fluid w-100" src={articleImg} alt="Imagen"/>
                                    </div>
                                    <div className='px-1 py-3'>
                                        <p className="mb-2 color-primary">{new Date(article.createdAt).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })}</p>
                                        <h2 className="mb-3">{article.title}</h2>
                                        <p className="mb-2"  dangerouslySetInnerHTML={{ __html: article.content }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Linea3 />
            <div className="container-xxl pt-5">
                <div className="container">
                    <div className="text-center text-md-start pb-5 mx-auto pb-md-0 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "800px"}}>
                        <h1 className="display-6 mb-2">Artículos relacionados</h1>
                        <div className='py-5'>
                            <OwlCarousel items={numItem}  
                                className="owl-theme"
                                loop  
                                dots= {false}
                                nav  
                                margin={8} >  
                                {noticias.map( (d, i) => {
                                    return (
                                        <div key={i} className="item">
                                            <div className="position-relative">
                                                <img className="img-small" src={d.img} alt="" />
                                            </div>
                                            <div className='px-1 py-3'>
                                                <p className="mb-2 color-primary" style={{fontSize: ".8em"}}>{d.fecha}</p>
                                                <h4 className="mb-3">{d.titulo}</h4>
                                            </div>
                                        </div>
                                    )
                                })}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
    );
}
 
export default withRouter(Trend);
import React, { useEffect, useState } from "react";
import Footer from "../static/footer";
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import bgImg from '../images/Contacto/Banner-superior-contacto.jpg';
import Ciudad from '../images/Contacto/img-marbella.jpg';
import useGeoLocation from "react-ipgeolocation";
import Maps from '../static/Maps';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SEO from "../utilities/SEO";
import { useSucursales } from "../hooks";
const Contacto = () => {
    const { t, i18n } = useTranslation("global");
    let loc_country = useGeoLocation().country;
    loc_country = loc_country === undefined ? '': loc_country.toLowerCase();
    const {loading, data, fetchData, setLoading} = useSucursales();
    const [oficinas, setOficinas] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // const oficinas = [];
    const obtainData = async() => {
        await fetchData({ offset: 1, limit: 1000 });
    }
    useEffect(() => {
        const obtainData = async () => {
            await fetchData({ offset: 1, limit: 1000, filtros:{estado:true} });
            setLoaded(true);
        };

        obtainData();
    }, []);

    useEffect(() => {
        if (loaded && !loading) {
            const updatedOficinas = data.rows.map(oficina => ({
                lat: parseFloat(oficina?.latitud),
                lng: parseFloat(oficina?.longitud)
            }));
            setOficinas(updatedOficinas);
        }
    }, [data, loaded, loading]);

    return ( 
        <>
            {SEO({title: t("contacto.oficinas"), type: 'website', ruta: "/oficinas"})}
            <Menu />
            
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">{t("contacto.oficinas")}</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
        
            <div id='oficinas' className="container-xxl py-5">
                <div className="container py-5">
                    <div className="row g-0">
                        {data.rows.sort((a, b) => a.municipio.localeCompare(b.municipio)).map(oficina => (
                            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s" key={oficina.id}>
                                <div className="h-100 p-5">
                                    <h5 className="mb-3">
                                        {t("contacto.oficina").toUpperCase()} {oficina.municipio.toUpperCase()}
                                    </h5> 
                                    <p className="mb-0">{oficina.direccion}</p>
                                    <p>{oficina.cp} {oficina.municipio}, {oficina.provincia}</p>
                                    <p className="mb-0">
                                        <b>Email: </b><Link to={`mailto:${oficina.email}`}>{oficina.email}</Link>
                                    </p>
                                    <p className="mb-0">
                                        <b>Tel: </b>{oficina.telefono}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div id="oficinas" className="container-fluid p-0 d-flex" style={{alignItems: "center"}}>
                <div className="container-fluid p-0">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                        <Maps coordenadas={oficinas} oficinas={true} />
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .parallax{
                    background-image: url(${`'${Ciudad}'`});
                    min-height: 500px;
                    background-attachment: fixed;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                .bg-imagen{
                    background-image: url(${`'${bgImg}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .react-tel-input .flag-dropdown {
                    background-color:white !important;
                }
                .react-tel-input .form-control, .react-tel-input .flag-dropdown {
                    border-bottom: none !important;
                }
                .react-tel-input{
                    border-bottom: 1px solid #dee2e6 !important;
                }
                
            `}</style>
        </>

    );
}
 
export default Contacto;
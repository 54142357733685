import Footer from "../static/footer";
import Menu from "../static/menu";
import Img from "./../images/TextosLegales/Banner-superior-textos-legales.jpg"
import Linea1 from "../static/linea1";
import { useTranslation } from "react-i18next";
import SEO from "../utilities/SEO";

const PoliticaCookies = () => {
    const { t } = useTranslation("global");
    return ( 
        <>
        {SEO({title: "Política de cookies", type: 'website', ruta: "/politica-cookies"})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Política de cookies</h1>
                    </div>

                </div>
            </div>
            <Linea1 />
            <div className="container-xxl pd-5 pdb-5">
                <div className="container">
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿QUÉ ES UNA COOKIE?</h4>
                        <p>
                        Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación o personalizas la forma en la que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación. 
                        </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿QUIÉN UTILIZA LA INFORMACIÓN DE LAS COOKIES?</h4>
                        
                        <table className="table text-center ">
                            <tbody>
                                <tr>
                                    <td>Nombre de la empresa</td>
                                    <td>Lae Consulting Normativas, S.L.</td>
                                </tr>
                                <tr>
                                    <td>NIF</td>
                                    <td>B75010686</td>
                                </tr>
                                <tr>
                                    <td>Dirección</td>
                                    <td>C/ Juan F. Gilisagasti, 2, 20018, San Sebastián, Guipúzcoa</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿QUÉ TIPOS DE COOKIES EXISTEN?</h4>
                        <p>
                            Las Cookies pueden clasificarse, en virtud de sus características propias, dentro de diversas categorías
                        </p>
                        <ol>
                            <li>
                                Según la entidad que la gestione:
                                <ul>
                                    <li>Cookies propias: Son aquellas que se envían a tu ordenador y son gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web.</li>
                                    <li>Cookies de terceros: Son aquellas que se envían a tu ordenador y no son gestionadas por nosotros sino por una tercera entidad. </li>
                                </ul>
                            </li>
                                Según el plazo de tiempo que permanecen activadas.
                                <ul>
                                    <li>Cookies de sesión: Este tipo de cookies recaban y almacenan datos únicamente mientras el Usuario accede al Sitio Web. </li>
                                    <li>Cookies persistentes: Mediante este tipo de cookies los datos siguen almacenados y pueden ser accedidos y tratados durante el periodo definido por el responsable de la cookie, y puede ir de unos minutos a varios años.</li>
                                </ul>
                            <li>
                                Según la finalidad:
                                <ul>
                                    <li>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través del sitio web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que el mismo ofrece, como por ejemplo, controlar  el  tráfico  y  la comunicación   de   datos,   identificar   la   sesión,   acceder   a   partes   de   acceso restringido, recordar los elementos que integran un pedido,  realizar el proceso de compra  de  un  pedido,  realizar  la  solicitud  de  inscripción  o  participación  en  un evento,   utilizar   elementos   de   seguridad   durante   la   navegación,   almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</li>
                                    <li>Cookies de personalización: Son aquéllas que permiten al usuario accede al servicio con algunas características de carácter general predefinidas, como por ejemplo el idioma, la configuración regional desde donde accede al servicio, etc. </li>
                                    <li>Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y  análisis del comportamiento de los usuarios de los sitios web a los que  están  vinculadas.  La  información  recogida  mediante  este  tipo  de  cookies  se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para  la  elaboración  de  perfiles  de  navegación  de  los  usuarios  de  dichos  sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
                                    <li>Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, se haya incluido en el sitio web, aplicación o plataforma desde la que presta el servicio solicitado en base a  los criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
                                    <li>Cookies de publicidad comportamental: Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en su página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿QUÉ TIPOS DE COOKIES UTILIZA ESTE SITIO WEB?</h4>
                        <p>A continuación, se especifican las cookies en concreto empleadas en el presente Sitio Web:</p>
                        <div className="necessary">
                            <p style={{ fontWeight: "bold"}}>Necesarias (3)</p>
                            <p>Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.</p>
                            <table id="cookies" className="table text-center ">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Proveedor</th>
                                        <th>Propósito</th>
                                        <th>Caducidad</th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>rc::a</td>
                                        <td>Google</td>
                                        <td>Esta cookie se utiliza para distinguir entre humanos y bots. Esto es beneficioso para la web con el objeto de elaborar informes válidos sobre el uso de su web.</td>
                                        <td>Persistente</td>
                                        <td>HTML Local Storage</td>
                                    </tr>
                                    <tr>
                                        <td>rc::c</td>
                                        <td>Google</td>
                                        <td>Esta cookie se utiliza para distinguir entre humanos y bots.</td>
                                        <td>Session</td>
                                        <td>HTML Local Storage</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>Esta tabla puede ser actualizada conforme a las cookies empleadas en cada momento. Te aconsejamos que consulte nuestra Política de Cookies de forma regular. </p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿SE REALIZAN TRANFERENCIAS DE DATOS A TERCEROS PAÍSES?</h4>
                        <p>Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (Ver los enlaces facilitados en la tabla anterior, en el apartado de cookies de terceros).</p>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿CÓMO DESHABILITAR Y BLOQUEAR COOKIES? </h4>
                        <p>Te informamos que, en cualquier caso, puedes deshabilitar o bloquear las cookies activando la configuración de tu navegador, permitiéndote rechazar la instalación de todas o algunas de las cookies. En caso de no permitir la instalación de cookies es posible que no puedas acceder a algunas de las funcionalidades del presente sitio web, y por tanto, tu experiencia como usuario en el sitio web resulte menos satisfactoria.</p>
                        <p>Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en tu ordenador. Para más información sobre cómo ajustar sus configuraciones de cookies en los siguientes navegadores, te remitimos al enlace pertinente:</p>
                        <ul>
                            <li>
                                <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies ">Internet Explorer</a>
                            </li>
                            <li>
                                <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we ">Firefox</a>
                            </li>
                            <li>
                                <a href="http://support.google.com/chrome/bin/answer.py?hl=en&answer=9564">Chrome</a>
                            </li>
                            <li>
                                <a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES">Safari</a>
                            </li>
                            <li>
                                <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">Opera</a>    
                            </li>    
                        </ul>
                    </div>
                    <div className="mx-auto wow fadeInUp mb-5" data-wow-delay="0.1s" style={{maxWidth: "800px", visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp"}}>
                        <h4 className="mb-4">¿QUÉ DERECHOS TIENES SOBRE LOS DATOS PERSONALES APORTADOS?</h4>
                        <p>Como usuario, puedes ejercitar los siguientes derechos</p>
                        <ul>
                            <li>Derecho de acceso: Tienes derecho a obtener por parte de la empresa confirmación de si se están tratando o no tus datos personales que, y en su caso, qué datos trata.</li>
                            <li>Derecho de rectificación: Tienes derecho a obtener por parte de la empresa,  la rectificación de los datos personales inexactos que te conciernen. En la solicitud de rectificación deberás indicar los datos que deseas que se modifiquen.</li>
                            <li>Derecho de oposición: Tienes derecho a oponerte en cualquier momento, por motivos relacionados con tu situación particular, a que datos personales que te conciernan sean objeto de un tratamiento basado en el interés legítimo de la empresa. En ese caso, la empresa dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento  que prevalezcan sobre tus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.</li>
                            <li>Derecho de supresión: Tienes derecho a obtener por parte de la empresa, la supresión de los datos personales que te conciernan.</li>
                            <li>Derecho a la portabilidad de los datos: Tienes derecho a que la empresa transfiera tus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en tu consentimiento y el tratamiento se realice por medios automatizados.</li>
                            <li>Derecho a la limitación del tratamiento: Tienes derecho a solicitarnos que suspendamos el tratamiento de tus datos cuando: impugnes la exactitud de los datos, mientras la empresa verifica dicha exactitud; hayas ejercido el derecho de oposición al tratamiento de tus datos, mientras que se verifica si los motivos legítimos de la empresa prevalecen sobre los tuyos como interesado.</li>
                            <li>Derecho a no ser objeto de decisiones individuales automatizadas, incluida la elaboración de perfiles: Tienes derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos o te afecte significativamente de modo similar.</li>
                        </ul>
                        <p>
                            En cualquier momento, podrás ejercitar estos Derechos, mediante un escrito y acreditando Tu personalidad dirigido al responsable del tratamiento, a la dirección que aparece más arriba.
                        </p>
                        <p>
                            Además, podrás presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales infringe la legislación aplicable.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }                
            `}</style>
        </>
     );
}
 
export default PoliticaCookies;
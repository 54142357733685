import React, { useState, useEffect, useRef } from "react";
import Footer from "../static/footer";
import Menu from "../static/menu";
import Linea1 from "../static/linea1";
import bgImg from '../images/Contacto/Banner-superior-contacto.jpg';
import Ciudad from '../images/Contacto/img-marbella.jpg';
import axios from 'axios';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PoliticaContacto from "../static/modalPoliticaContacto";
import PhoneInput from 'react-phone-input-2';
import useGeoLocation from "react-ipgeolocation";
import { useTranslation } from "react-i18next";
import { FaXTwitter } from "react-icons/fa6";
import SEO from "../utilities/SEO";

const { REACT_APP_API_URL } = process.env;

const Formacion = () => {
    const { t, i18n } = useTranslation("global");
    const [show, setShow] = useState(false);
    const [ datos, setDatos ] = useState({});
    const inputRef = useRef(null);
    let loc_country = useGeoLocation().country;
    loc_country = loc_country === undefined ? '': loc_country.toLowerCase();
    const showPhone = (val,coun,ev,form) =>{
        
        const telefono = form
        setDatos({
            ...datos,
            [ev.target.name]: telefono
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        ReactRecaptcha3.getToken().then(async token => {
            const toastInfo = toast.info("Tus datos se están enviando",{autoClose:false});
            
            datos.token = token;
            await axios.post(`${REACT_APP_API_URL}/sendEmail/formacion`, datos)
            .then(res => {
                console.log(res)
                if(res.status == 202){
                    if(res.data.message === 'EMAIL_SENDED'){
                        e.target.reset();
                        document.getElementById('telefono').value = '';
                        //quitarClaseTel()
                        //toast.success("Correo enviado correctamente")
                        toast.update(toastInfo, {
                            render: "Correo enviado correctamente",
                            type: toast.TYPE.SUCCESS,
                            autoClose: true
                        });
                    }else{
                        //toast.error("No se ha podido enviar el email");
                        toast.update(toastInfo, {
                            render: "No se ha podido enviar el email",
                            type: toast.TYPE.ERROR,
                            autoClose: true
                        });
                        console.log("No se ha podido enviar el email")
                    }
                }else{
                    //toast.error("Ha ocurrido un error inesperado")
                    toast.update(toastInfo, {
                        render: "Ha ocurrido un error inesperado",
                        type: toast.TYPE.ERROR,
                        autoClose: true
                    });
                }
                
            }).catch(err => {
                console.log(err.response);
                toast.error("Ha ocurrido un error inesperado");
            });
            
            
        }, error => {
            // handle error here
            console.log(error);      
        });
    };
    const handleChange = (e) => {
        let value = ''
        if(e.target.name=== 'terms' || e.target.name==='suscribir'){
            value = e.target.checked
        }else{
            value = e.target.value
        }
        setDatos({
          ...datos,
          [e.target.name]: value
        });
    }
    useEffect(() => {
        ReactRecaptcha3.init('6LeaJ_4kAAAAAOur2P6Q1eN3cakeY8_Hyra2wnxk');
    },[]);
    return (
        <>
            <Menu/>
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Formación</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl py-5 contact-info ">
                <div className="container ">
                    <div className="row g-5 pt-5 justify-content-md-center">
                        <div className="col-lg-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 className="mb-4">{t("formacion.titulo")}</h3>
                            <p className="mb-4">
                                <b>{t("formacion.direccion")}: </b> LAE HOMES MADRID - Glorieta de Quevedo 9, 3º
                                <br/>
                                <b>{t("formacion.dia")}: </b>20 de julio
                                <br/>
                                <b>{t("formacion.hora")}: </b>11h
                                <br/>
                                {t("formacion.texto")}
                            </p>
                            <form id="formContacto" onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="nombre" id="nombre" placeholder="Nombre y apellidos" required />
                                            <label htmlFor="nombre">{t("contacto.nombre")} <span style={{ color: 'red'}}>*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="email" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="email" id="email" placeholder="Email de contacto" required />
                                            <label htmlFor="email">{t("contacto.email")} <span style={{ color: 'red'}}>*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                        <PhoneInput ref={inputRef} onChange={(val,coun,ev,form) =>showPhone (val,coun,ev,form)} country={loc_country} inputProps={{
                                            name: 'telefono',
                                            id:"telefono",
                                            required: true,
                                            autoFocus: true
                                        }}  placeholder={t("contacto.telefono")} />
                                    
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <input type="checkbox" className="form-check-input-register" style={{ marginRight: '10px'}} onChange={handleChange} name="terms" id="terms" required />
                                        <label htmlFor="terms">{t("contacto.terms1A")} <u><Link to="/politica-privacidad" target={"_blank"}>{t("politicaPrivacidad")}</Link></u> {t("contacto.terms1B")}</label>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-borde py-3 px-5" style={{ width: '200px'}} type="submit">{t("contacto.enviar")}</button>
                                    </div>
                                    <div className="col-12">
                                        <Link to="#" onClick={()=>{setShow(true)}}><small style={{ color: "#aeaeae"}}>{t("contacto.informacionBasicaPD")}</small></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .parallax{
                    background-image: url(${`'${Ciudad}'`});
                    min-height: 500px;
                    background-attachment: fixed;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                .bg-imagen{
                    background-image: url(${`'${bgImg}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                }
                .react-tel-input .flag-dropdown {
                    background-color:white !important;
                }
                .react-tel-input .form-control, .react-tel-input .flag-dropdown {
                    border-bottom: none !important;
                }
                .react-tel-input{
                    border-bottom: 1px solid #dee2e6 !important;
                }
                
            `}</style>
        </>
    );
}

export default Formacion;

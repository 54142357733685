import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import $ from 'jquery'
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
const FiltroObranueva = ({ handleFiltroSubmit, setFiltros, filtros }) => {
    const { t } = useTranslation("global");

    const [provincia, setProvincia] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [ciudadesPorProvincia, setCiudadesPorProvincia] = useState([]);
  
    const [precioMinimo, setPrecioMinimo] = useState('');
    const [precioMaximo, setPrecioMaximo] = useState('');
    
    const [disabled, setDisabled ] = useState(true);
   
    const handleChange = (e) => {
        if(e.target.name == 'provincia'){
            const ciudadesPorProvincia = municipalities.filter((ciudad) => ciudad.provCode === e.target.value);
            let provinciaSelecionada = provinces.filter((provinc) => provinc.code === e.target.value)[0]?.name;
            
            console.log(provinciaSelecionada)
            setCiudadesPorProvincia(ciudadesPorProvincia);
            setDisabled(false);
            setProvincia(e.target.value);
            
            setFiltros({...filtros,[e.target.name]:provinciaSelecionada});
        }
        if(e.target.name == 'ciudad'){
            
            setFiltros({...filtros,[e.target.name]:e.target.value});
            setCiudad(e.target.value);
        }
        if (e.target.name === 'precioMinimo') {
            setPrecioMinimo(e.target.value);
            setFiltros({...filtros,[e.target.name]:e.target.value});
        }
        if (e.target.name === 'precioMaximo') {
            setPrecioMaximo(e.target.value);
            setFiltros({...filtros,[e.target.name]:e.target.value});
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(filtros);
        // handleFiltroSubmit(filtros);
    };
    return (
        <>
            <div className="pad-5 col-lg-4 col-md-12 mb-3">
                <select
                id="provincia"
                name="provincia"
                value={provincia}
                onChange={(e) => {handleChange(e)}}
                className="form-select"
                >
                <option value="">{t("filtros.provincias")}</option>
                {provinces
                    .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar las provincias por nombre
                    .map((provincia) => ( // Mapear las provincias para renderizar las opciones
                        <option key={provincia.code} value={provincia.code}>
                            {provincia.name}
                        </option>
                ))}
                </select>
            </div>

            <div className="pad-5 col-lg-4 col-md-12 mb-3">
                <select
                    id="ciudad"
                    name="ciudad"
                    value={ciudad}
                    onChange={(e) => {handleChange(e)}}
                    className="form-select"
                    disabled={disabled}
                >
                    <option value="">{t("filtros.ciudades")}</option>
                    {ciudadesPorProvincia.map((ciudad) => (
                        <option key={ciudad.code} value={ciudad.name}>
                            {ciudad.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="pad-5 col-lg-2 col-md-12 mb-3">
                <div className="dropdown">
                    <a className="form-select text-left" id="precios" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {precioMaximo === '' && precioMinimo === '' ? t("filtros.precio") : precioMinimo+' - '+precioMaximo}
                    </a>
                    <div id="prmm" className="dropdown-menu fadedown" data-wow-delay="0.1s" aria-labelledby="precios">
                        <div className="input-group">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control border-0 border-bottom me-2"
                                    style={{ borderRadius: 0}} 
                                    value={precioMinimo}
                                    onChange={(e) => {handleChange(e)}}
                                    name='precioMinimo'
                                    placeholder={t("filtros.minimo")}
                                />
                                <label className="form-label">{t("filtros.minimo")}</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    id="maximo"
                                    type="text"
                                    className="form-control border-0 border-bottom ms-2"
                                    style={{ borderRadius: 0}} 
                                    value={precioMaximo}
                                    onChange={(e) => {handleChange(e)}}
                                    name='precioMaximo'
                                    placeholder={t("filtros.maximo")}
                                />
                                <label htmlFor="maximo" className="form-label">{t("filtros.maximo")}</label>
                            </div>
                            {/* <div className="form-floating">
                                <input type="number" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="nombre" id="nombre" placeholder="Nombre y apellidos" required />
                                <label htmlFor="nombre">{t("contacto.nombre")} <span style={{ color: 'red'}}>*</span></label>
                            </div> */}
                        </div>
                    </div>
                </div>
         
            </div>
            <div className="col-lg-2 col-md-12 mb-3">
                <button type="button" onClick={(e)=>handleFiltroSubmit(e)} className='btn btn-primary text-white' style={{ borderRadius: '8px', width: '120px'}}>{t("filtros.buscar").toUpperCase()}</button>
                {/* <button className="btn btn-primary" onClick={handleSubmit}>
                    {t("filtros.buscar").toUpperCase()}
                </button> */}
            </div>
            <style jsx="true">{`
                .pad-5 {
                    padding-left : 10px !important ;
                    padding-right : 10px !important ;
                }
                
            `}</style>
        </>
        
    );
}

export default FiltroObranueva;
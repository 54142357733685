/* export const spinner = () => {
    setTimeout(() => {
            document.getElementById('spinner').classList.remove('show');
        
    }, 1);
};
spinner();


window.scroll(function () {
    if (this.scrollTop() > 300) {
        document.getElementById('.sticky-top').classList.add('shadow-sm').css('top', '0px');
        document.getElementById('#logo').classList.add('sticky-logo').classList.remove('no-sticky-logo');
        document.getElementById('#simbolo').classList.add('no-sticky-logo').classList.remove('sticky-logo');
        document.getElementById('#menu-top').classList.remove('py-3');
    } else {
        document.getElementById('.sticky-top').classList.remove('shadow-sm').css('top', '-100px');
        document.getElementById('#logo').classList.remove('sticky-logo').classList.add('no-sticky-logo');
        document.getElementById('#simbolo').classList.remove('no-sticky-logo').classList.add('sticky-logo');
        document.getElementById('#menu-top').classList.add('py-3');
    }
}); */
import $ from 'jquery'

export const quitarAcentos = (function() {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç", 
        to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};
   
    for(var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );
   
    return function( str ) {
        var ret = [];
        for( var i = 0, j = str.length; i < j; i++ ) {
            var c = str.charAt( i );
            if( mapping.hasOwnProperty( str.charAt( i ) ) )
                ret.push( mapping[ c ] );
            else
                ret.push( c );
        }      
        return ret.join( '' );
    }
   
  })();

export const abrirMenu = () => {
    document.getElementById('sidebar').classList.add('active');

}
export const cerrarMenu = () => {
    document.getElementById("sidebar").classList.remove("active");

}

export const abrirModal = () => {
    console.log("AA");
}

$('#dmp').hover(
    function() {
      $('#navbarDropdownP').css('color', '#9F8A52 !important');
    },
    function() {
      $('#navbarDropdownP').css('color', '#ffffff !important');
    }
  );
$('#navbarDropdownP').mouseover(function () {
    $(this).attr('aria-expanded', 'true');
    $('#dmp').addClass('show');
}).mouseout(function () {
        $(this).attr('aria-expanded', 'false');
        $('#dmp').removeClass('show');
});
$('#dmp').mouseover(function () {
    $(this).attr('aria-expanded', 'true');
    $('#dmp').addClass('show');
}).mouseout(function () {
    $(this).attr('aria-expanded', 'false');
    $('#dmp').removeClass('show');
});
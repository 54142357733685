import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../providers/ModalContext";
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import img1 from './../images/popup/popup-obranueva-irune.jpg';
import img2 from './../images/popup/popup-obranueva-pirineos.jpg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaTimes } from 'react-icons/fa';
import { setCookie, getCookie } from "../utilities/createCookie";
// import SlideVirune from './../images/slider/Slide-villa-irune.png';
const ModalPromos = () => {
    const { t, i18n } = useTranslation("global");
    const { isModalVisible, setIsModalVisible, number, data } = useContext(ModalContext);
    const [promo, setPromo] = useState({});
    console.log();
    useEffect(()=>{
        if(data?.rows){
            setPromo(data.rows.find((dt)=> dt.id === number));
        }

    },[data?.rows]);

    
    const handleClose = () =>  {
        setIsModalVisible(false);
        setCookie("modal-promos", "true", 30); 
    }
    return(
        <>
            <Modal 
                show={isModalVisible} 
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Row>
                        <button onClick={handleClose} className="close-button-modal">
                            <FaTimes />
                        </button>
                        <Col xs={12} md={6}>
                            <img src={promo?.id === 1 ? img1: img2} className="img-pop"/>
                        </Col>
                        <Col xs={12} md={6} style={{padding:'20px'}}>
                            <div>
                                {promo?.id === 1 ?
                                        <>
                                            {t("popup.villairune.proxpromo")
                                            .toUpperCase()
                                            .split(/<br\s*\/?>/i)
                                            .map((part, index) => (
                                                <h1 key={index} style={{ color: index === 0 ? '#9F8A52' : '#384043' }}>
                                                    {part}
                                                </h1>
                                            ))}

                                        </>
                                    :
                                        <>
                                            {t("popup.villapirineos.proxpromo")
                                            .toUpperCase()
                                            .split(/<br\s*\/?>/i)
                                            .map((part, index) => (
                                                <h1 key={index} style={{ color: index === 0 ? '#9F8A52' : '#384043' }}>
                                                    {part}
                                                </h1>
                                            ))}
                                        </>
                                }
                                <p >
                                
                                </p>
                                {promo?.id === 1 ? 
                                    <>
                                        <span className="text-bold color-gray" >
                                            {promo?.nombre?.toUpperCase()}
                                        </span>
                                        <p className="font-size" dangerouslySetInnerHTML={{__html:t("popup.villairune.ppromo")}} />
                                        <Link className="btn btn-lae-modal" to={"https://villairune.laehomes.com/"} target="_blank">
                                            {t("popup.villairune.btninfor").toLocaleUpperCase()}
                                        </Link>
                                    </>
                                :
                                    <>
                                        <span className="text-bold color-gray" >
                                            {promo?.nombre?.toUpperCase()}
                                        </span>
                                        <p className="font-size" dangerouslySetInnerHTML={{__html:t("popup.villapirineos.ppromo")}} />
                                        <Link className="btn btn-outlined-gray" to={"https://villapirineo.laehomes.com/"} target="_blank">
                                            {t("popup.villapirineos.btninfor").toLocaleUpperCase()}
                                        </Link>
                                    </>
                                    
                                }
                                
                            </div>
                        </Col>    
                    </Row>
                </Modal.Body>
            </Modal>
            <style jsx="true">{`
                .img-pop{
                    width:99%;
                }
                .modal-content{
                    border-radius: 3px;
                }
                .text-bold {
                    font-weight: bold;
                    font-size: 20px;
                }
                .color-gray{
                    color: #384043;
                }
                .font-size{
                    font-size:20px !important;
                    font-weight: 100;
                }
                .btn-outlined-gray{
                    border: 1px solid;
                    padding: 15px 40px 15px 40px !important;
                    border-radius: 3px;
                    font-weight: normal !important;
                }
                .btn-outlined-gray:hover{
                    background-color: #8E7D36;
                    color: white;
                }
                .btn-lae-modal{
                    padding: 15px 50px 15px 50px !important;
                    border-radius: 3px;
                    font-weight: normal !important;
                    background-color:#9F8A52;
                    color: white;
                }
                .btn-lae-modal:hover{
                    padding: 15px 50px 15px 50px !important;
                    border-radius: 3px;
                    font-weight: normal !important;
                    background-color:transparent;
                    border-color:#9F8A52;
                    color: #8E7D36;
                }
                .close-button-modal{
                    position: absolute;
                    top: 10px ;
                    left: 46%; 
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    color:#384043;
                }

            `}</style>
        </>
    );
}   

export default ModalPromos ;
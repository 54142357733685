import React, { useEffect, useState } from "react";

const PoliticaContacto = (props) => {
    const { show, onClose } = props;
    const [open, setOpen] = useState(show);

    useEffect(() => {
        setOpen(show);
    }, [show]);

    return (
        <>
            <input id="modal-toggle" type="checkbox" checked={open ? "checked" : ""} onChange={() => {}} className="d-none" />
            <label className="modal-backdrop" htmlFor="modal-toggle" onClick={() => onClose(!open)}></label>
            <div className="modal-content">
                <label className="modal-close-btn" htmlFor="modal-toggle" onClick={() => onClose(!open)}>
                    <i id="btn-close" className="fas fa-times" />
                </label>
                <div className="container justify-content-lg-left" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <p className="text-left">
                        <b>INFORMACIÓN BÁSICA DE PROTECCIÓN DE DATOS: </b>
                        <i>
                            En cumplimiento del deber de informar a los interesados de las circunstancias y
                            condiciones del tratamiento de sus datos y de los derechos que le asisten,
                            ponemos a su disposición la siguiente información.
                        </i>
                    </p>
                </div>
                <div className="row">
                    <div className="col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td colSpan="2"><u>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</u></td>
                                </tr>
                                <tr>
                                    <td className="text-left">RESPONSABLE DEL TRATAMIENTO</td>
                                    <td className="text-left">
                                        <i>
                                            Responsable: Lae Consulting Normativas, S.L. - CIF: B75010686 Dir. Postal: C/ Juan F. Gilisagasti, 2, 20018, San Sebastián, Guipúzcoa
                                        </i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">FINALIDAD</td>
                                    <td className="text-left"><i>Gestionar su consulta/petición y dar una respuesta a la misma.</i></td>
                                </tr>
                                <tr>
                                    <td className="text-left">LEGITIMACIÓN</td>
                                    <td className="text-left"><i>Su consentimiento</i></td>
                                </tr>
                                <tr>
                                    <td className="text-left">DESTINATARIOS</td>
                                    <td className="text-left">
                                        <i>
                                            Los datos no se cederán a terceros salvo en los casos en los que exista una obligación legal o comunicación necesaria para dar contestación a su consulta.
                                        </i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">DERECHOS</td>
                                    <td className="text-left">
                                        <i>
                                            Puede ejercer sus Derechos a acceder, rectificar, oponerse, limitar, portar y suprimir los datos ante el responsable del tratamiento; además de acudir a la autoridad de control competente (AEPD).
                                        </i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">INFORMACIÓN ADICIONAL</td>
                                    <td className="text-left">
                                        <i>Puede consultar la información adicional sobre el tratamiento de sus datos personales aquí.</i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <style jsx="true">
                {`
                    .modal-btn {
                        padding: 10px 20px;
                        color: var(--primary);
                        font-size: 16px;
                        font-weight: bold;
                        outline: solid var(--primary) 2px;
                        cursor: pointer;
                      }
                      .logo-modal{
                        width: 50%;
                        margin: 10px;
                        /* padding: 10px; */
                      }
                      #modal-toggle {
                        /* display: none; */
                      }
                      
                      .modal-content,
                      .modal-backdrop {
                        opacity: 0;
                        position: fixed;
                        padding-top: 50px;
                        z-index: -1;
                        overflow: hidden;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                      }
                      
                      .modal-content {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        left: 0;
                        right: 0;
                        max-height: 30%;
                        min-height: 75%;
                        width: 70%;
                        max-width: 600px;
                        text-align: center;
                        transform: translateY(-10%);
                        /* z-index: 1500; */
                      }
                      
                      .modal-backdrop {
                        left: 0;
                        top: 0;
                        height: 100%;
                        height: 100vh;
                        width: 100%;
                        width: 100vw;
                        transform: scale(0);
                      }
                      
                      #modal-toggle:checked ~ .modal-backdrop {
                        opacity: 1;
                        background-color: var(--secondary);
                        opacity: .8;
                        transform: scale(1);
                        z-index: 1300;
                      }
                      
                      #modal-toggle:checked ~ .modal-content {
                        opacity: 1;
                        background-color: #fff;
                        Overflow: hidden;
                        pointer-events: auto;
                        cursor: auto;
                        z-index: 1300;
                        transform: translateY(0%);
                      }
                      .tab-modal{
                        padding: 14px;
                      }
                      @media (max-width: 600px) {
                        .modal-content {
                          top: 0;
                          left: 0;
                          height: 100vh;
                          width: 100vw;
                        }
                        .form-label-forgot {
                          width: 45% !important;
                        }
                        .tabs > .tabs-content form {
                          flex-direction: column;
                          padding: 5px !important;
                        }
                      }
                      .modal-close-btn {
                        font-size: 25px;
                        margin-top: 5px;
                        display: inline-block;
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        top: 5px;
                      }
                      .modal-close-btn svg {
                        transition: 0.2s;
                      }
                      .modal-close-btn svg line {
                        stroke-width: 5px;
                        stroke: var(--secondary);
                      }
                      .modal-close-btn:hover svg {
                        transform: rotate(90deg);
                      }
                      .modal-footer-homes{
                        background-color: var(--primary);
                        justify-content: center;
                        min-height: 17.11%;
                        text-align: center !important;
                        font-size: large;
                      }
            
                `}

            </style>
        </>
    );
}

export default PoliticaContacto;

import React, {useState, useEffect} from 'react'
import Footer from "../static/footer";
import Linea1 from "../static/linea1";
import Menu from "../static/menu";
import Img1 from "./../images/Trends/Banner-superior-trends.jpg"
import Inmueble1 from './../images/Inmueble-1.jpg'
import Inmueble2 from './../images/Inmueble-2.jpg'
import Slider2 from './../images/slider/Slide-home-2.jpg'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { getArticles } from '../providers/api';
import SEO from '../utilities/SEO';

const Trends = () => {

    const data = [
        // {id: "1", fecha: '10 Marzo 2023', img: 'https://3.bp.blogspot.com/-kRvaP5I4GXA/XJ9U4U7WCOI/AAAAAAAADXY/6ntBe3dxGlYLLZq9OxDGf4urEoejuBnvQCLcBGAs/w400/199.jpeg', titulo: 'El lugar perfecto 1', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "2", fecha: '11 Marzo 2023', img: 'https://images.pexels.com/photos/7319307/pexels-photo-7319307.jpeg', titulo: 'El lugar perfecto 2', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "3", fecha: '13 Marzo 2023', img: 'https://1.bp.blogspot.com/-nMSa4Nn7Ptc/XYnmptL7xaI/AAAAAAAAD6Y/4qZ7nfvQIbIx7UFSPYEP0l95WHEVjM6fwCNcBGAsYHQ/w400/168.jpg', titulo: 'El lugar perfecto 3', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "4", fecha: '14 Marzo 2023', img: 'https://4.bp.blogspot.com/-R-W4st0RZSI/WO-0p3MJOjI/AAAAAAAAAZw/78ifeoiqCWgk3g5rn06FzJMtDwTON6pcQCLcB/s1600/img-1-380x475.jpg', titulo: 'El lugar perfecto 4', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "5", fecha: '15 Marzo 2023', img: 'https://4.bp.blogspot.com/-_VUr4RH5TeA/WO-0zvhepeI/AAAAAAAAAaE/022bDzNgjxw7qRlhU23KCi2Mmte-moKwQCLcB/s1600/14-380x253.jpg', titulo: 'El lugar perfecto 5', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "6", fecha: '16 Marzo 2023', img: 'https://1.bp.blogspot.com/-9XnOoVlReAw/WO-1PTTNOzI/AAAAAAAAAak/QiZUHUYUE58mTl9B1tQMGVaigVr1FLF5gCLcB/s1600/6-380x380.jpg', titulo: 'El lugar perfecto 6', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "7", fecha: '17 Marzo 2023', img: 'https://3.bp.blogspot.com/-UZ6aTxCipsA/WO-0zNSI2eI/AAAAAAAAAZ4/kJyIJIwYjbACRAkCk8gMSBPBOwOjLmbDACPcB/s1600/11-380x253.jpg', titulo: 'El lugar perfecto 7', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "8", fecha: '18 Marzo 2023', img: 'https://2.bp.blogspot.com/-lO-_sTpNVXk/WO-004bHfdI/AAAAAAAAAaU/SK4XAHnHny0hH79ClImZNgZGprjRxfulACPcB/s1600/8-380x475.jpg', titulo: 'El lugar perfecto 8', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "9", fecha: '18 Marzo 2023', img: 'https://1.bp.blogspot.com/-vIKm5p_3OeA/WO-0zmwqV-I/AAAAAAAAAaA/f_IDerUTiL4mu2RVv68TwfhlGvT8bbgyACPcB/s1600/13-380x253.jpg', titulo: 'El lugar perfecto 9', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "10", fecha: '19 Marzo 2023', img:'https://1.bp.blogspot.com/-nxE5vIdt4Us/XTakuNcqhII/AAAAAAAADyY/EgJ7hpOLs10_h2k9yRNsFET1hKzG1nhZwCLcBGAs/w400/img-334.jpg', titulo: 'El lugar perfecto 10', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "11", fecha: '20 Marzo 2023', img:'https://2.bp.blogspot.com/-2lfoUvrFDk0/XJ9TgiebqyI/AAAAAAAADWw/7onsCw6RC0A2X1DwtoCiQAkNk26Q776aQCLcBGAs/w400/206.jpeg', titulo: 'El lugar perfecto 11', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "12", fecha: '21 Marzo 2023', img:'https://1.bp.blogspot.com/-xb8TF4SzdE8/XdUqK3UQENI/AAAAAAAAEE4/dbB6pmxuC08nzF67hizdg2eposOFteNlwCNcBGAsYHQ/w400/6.jpg', titulo: 'El lugar perfecto 12', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "13", fecha: '22 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 13', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "14", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-IdIUpQDP35I/XJ9VJFXG7MI/AAAAAAAADXg/8rboDT-FDxAyg2CygF120kh5_-lxVuh6gCLcBGAs/w400/202.jpeg', titulo: 'El lugar perfecto 14', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "15", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-OlCKjMigKQ0/XYdfUZqv-KI/AAAAAAAAD6A/anwZ57Ppsac_qUi1M7d0DB4uVxTgRCgPgCNcBGAsYHQ/w400/150.jpg', titulo: 'El lugar perfecto 15', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        // {id: "16", fecha: '24 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 16', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
    ]

    const perPage =20;
    const [offset, setOffset] = useState(0);
    const [selectedPage, setCurrentPage] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [article, setArticle] = useState([]);
    const [server, setServer] = useState('');
    const changePage  = (e) => {
        console.log(e.selected)
        const d = e.selected * perPage;
        console.log(d)
        setCurrentPage(e.selected)
        setOffset(d)
        receivedData(d, perPage)
    };

    const fetchArticle = async () => {
        try {
            const response = await getArticles();
            const { url } = response.config;
            // console.log(url);
            const match = url.match(/^https?:\/\/[^/]+/); // Buscar la parte inicial de la URL
            // const baseUrl = match ? match[0] : ""; // Obtener la URL base si se encontró una coincidencia
            setServer( match ? match[0] : "");
            // console.log(baseUrl); // Imprimir "http://localhost:4001"
            // console.log(response.data.data);
            
            setArticle(response.data.data);
        } catch (error) {
            console.log(error);            
        }
    }
    const receivedData = (o, p) => {
        // console.log(article);
        
    }

    useEffect(() => {
        fetchArticle();
        setpageCount(Math.ceil(article.length / perPage));
        receivedData(offset, perPage);
        
    },[]);
    // console.log(offset)
    return ( 
        <>
            {SEO({title: "Blog", type: 'website', ruta: "/blog"})}
            <Menu />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-4 text-white mb-1 text-white">Blog</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl py-5">
                <div className="container py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="row g-4 ">
                        {article?.slice(offset, offset + perPage).map( (d, i) => {
                            return (
                                <div key={i} className="col-lg-4" style={{  display: 'inline-block' }}>
                                    <div className="service-item position-relative h-100">
                                        <div className="trends-text">
                                            <div className="mx-auto">
                                                <img className="img-fluid" src={`${server}${d.img}`} alt="Imagen"/>
                                            </div>
                                            <div className='px-1 py-3'>
                                                <p className="mb-2 color-primary">{d.fechaPubli ? new Date(d.fechaPubli).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) : 'Sin Fecha de Publicación'}</p>
                                                <h2 className="mb-3">{d.title}</h2>
                                                <p className="mb-2">{/*d.texto IDIOMAS"Aqui va el texto"*/}</p>
                                                <p className="mb-2"><Link to={`/blog/${d.id}`} target={"_blank"} className=" btn btn-link p-0"><span className="me-3">LEER MÁS</span><small><i className="fa fa-plus color-primary"></i></small></Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='text-center'>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={6}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 400px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
     );
}
 
export default Trends;
export const cambiarFormatoNombre = (nombre) => {
    // Verifica si el nombre contiene una coma (,)
    if (nombre.includes(",")) {
        const partes = nombre.split(", ");
        if (partes.length === 2) {
            // Si hay dos partes después de dividir por la coma, cambia el formato
            return partes[1] + " " + partes[0];
        }
    }
    // Si no se cumple la condición anterior, devuelve el nombre sin cambios
    return nombre;
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import $ from 'jquery'
import { getProvincias, getCiudades, getTipos } from '../providers/api';
import { useTipoPropiedades }  from '../hooks';
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { cambiarFormatoNombre } from '../utilities/dataFormat';

const FiltroPropiedades = ({ handleFiltroSubmit, setFiltros, filtros }) => {
    const { t, i18n } = useTranslation("global");
    const { language } = i18n;
    const [idioma, setIdioma] = useState(1);
    const {loading, data, fetchData} = useTipoPropiedades();
    const [provincia, setProvincia] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [ciudadesPorProvincia, setCiudadesPorProvincia] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [zonasPorCiudad, setZonasPorCiudad] = useState({});
    const [zona, setZona] = useState('')
    const [tipo, setTipo] = useState('');
    const [exclu, setExclu] = useState('');
    const [precioMinimo, setPrecioMinimo] = useState('');
    const [precioMaximo, setPrecioMaximo] = useState('');
    const [ciudades, setCiudades] = useState([]);
    

    const handleChange = (e) => {
        console.log(e.target.value)
        if(e.target.name == 'provincia'){
            console.log("ENTROOOO");
            
            const ciudadesPorProvincia = municipalities.filter((ciudad) => ciudad.provCode === e.target.value);
            console.log(e.target.value);
            let provinciaSelecionada = provinces.filter((provinc) => provinc.code === e.target.value)[0]?.name;
         
            ciudadesPorProvincia.map(ciu => { ciu.name = cambiarFormatoNombre(ciu.name); return ciu;});

            setCiudadesPorProvincia(ciudadesPorProvincia);
            setDisabled(false);
            setProvincia(e.target.value);
            console.log(filtros);
            const filtroClean = {...filtros,ciudad:null};
            console.log(filtroClean);
            
            setFiltros({...filtroClean,[e.target.name]:provinciaSelecionada});
        }
        if(e.target.name == 'ciudad'){
            
            setFiltros({...filtros,[e.target.name]:e.target.value});
            setCiudad(e.target.value);
        }
        if(e.target.name == 'tipo'){
            
            console.log(e.target.value);
            setFiltros({...filtros,[e.target.name]:e.target.value});
            setTipo(e.target.value);
        }
        if (e.target.name === 'precioMinimo') {
        
            setPrecioMinimo(e.target.value);
            setFiltros({...filtros,[e.target.name]:e.target.value});
        }
        if (e.target.name === 'precioMaximo') {
            setPrecioMaximo(e.target.value);
            setFiltros({...filtros,[e.target.name]:e.target.value});
        }
        if(e.target.name === 'exclu'){
            setExclu(e.target.checked);
            setFiltros({...filtros,[e.target.name]: e.target.checked ?'1': null});
        }
    };

    useEffect(()=>{
        switch (language) {
            case 'es':
                setIdioma(1);
                break;
            case 'en':
                setIdioma(2);
                break;
            default:
                setIdioma(1);
        }
        fetchData();
        // setLoading(false);
    },[loading]);
    
  

    return (
        <>
            <div className="col-lg-3 col-md-12 mb-3">
                <select
                    id="provincia"
                    name="provincia"
                    value={provincia}
                    onChange={(e) => {handleChange(e)}}
                    className="form-select"
                >
                <option value="">{t("filtros.provincias")}</option>
                {provinces
                    .sort((a, b) => a.name.localeCompare(b.name)) // Ordenar las provincias por nombre
                    .map((provincia) => ( // Mapear las provincias para renderizar las opciones
                        <option key={provincia.code} value={provincia.code}>
                            {provincia.name}
                        </option>
                ))}
                </select>
            </div>

            <div className="col-lg-3 col-md-12 mb-3">
                <select
                    id="ciudad"
                    name="ciudad"
                    value={ciudad}
                    onChange={handleChange}
                    className="form-select"
                    disabled={disabled}
                >
                    <option value="">{t("filtros.ciudades")}</option>
                    {ciudadesPorProvincia.map((ciudad) => (
                        <option key={ciudad.code} value={ciudad.name}>
                            {ciudad.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-lg-2 col-md-12 mb-3">
                <select
                    id="tipo"
                    name="tipo"
                    value={tipo}
                    onChange={(e) => handleChange(e)}
                    className="form-select"
                >
                    <option value="">{t("filtros.tipos")}</option>
                    {data?.map((tipo) => (
                       <option key={tipo?.id} value={tipo?.nombre}>
                            {tipo?.traduccionTipo
                                .filter(tl => tl.id_idioma === idioma)
                                .map(tl => tl.nombre)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-lg-2 col-md-12 mb-3">
                <div className="dropdown">
                    <a className="form-select text-left" id="precios" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {precioMaximo === '' && precioMinimo === '' ? t("filtros.precio") : precioMinimo+' - '+precioMaximo}
                    </a>
                    <div id="prmm" className="dropdown-menu fadedown" data-wow-delay="0.1s" aria-labelledby="precios">
                        <div className="input-group">
                            <div className="form-floating">
                                <input
                                    type="number"
                                    className="form-control border-0 border-bottom me-2"
                                    style={{ borderRadius: 0}} 
                                    value={precioMinimo}
                                    name='precioMinimo'
                                    onChange={(e) => handleChange(e)}
                                    placeholder={t("filtros.minimo")}
                                />
                                <label className="form-label">{t("filtros.minimo")}</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    id="maximo"
                                    type="number"
                                    className="form-control border-0 border-bottom ms-2"
                                    style={{ borderRadius: 0}} 
                                    value={precioMaximo}
                                    name='precioMaximo'
                                    onChange={(e) => handleChange(e)}
                                    placeholder={t("filtros.maximo")}
                                />
                                <label htmlFor="maximo" className="form-label">{t("filtros.maximo")}</label>
                            </div>
                            {/* <div className="form-floating">
                                <input type="number" className="form-control border-0 border-bottom" style={{ borderRadius: 0}} onChange={handleChange} name="nombre" id="nombre" placeholder="Nombre y apellidos" required />
                                <label htmlFor="nombre">{t("contacto.nombre")} <span style={{ color: 'red'}}>*</span></label>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-2 col-md-12 mb-3">
                <div className="form-check-filter form-check-inline-f">
                    <label className="form-check-label-filter-f" htmlFor="flexCheckIndeterminate">
                    {t("propiedades.propiedadesExclusivas")}
                    </label>
                    <input className="form-check-input-filter-f" name="exclu" type="checkbox" value={exclu} id="flexCheckIndeterminate" onChange={(e)=>handleChange(e)}/>
                </div>
            </div> */}
            <div className="col-lg-2 col-md-12 mb-3">
                <button type="button" onClick={(e)=>handleFiltroSubmit(e)} className='btn btn-primary text-white' style={{ borderRadius: '8px', width: '120px'}}>{t("filtros.buscar").toUpperCase()}</button>
                {/* <button className="btn btn-primary" onClick={handleSubmit}>
                    {t("filtros.buscar").toUpperCase()}
                </button> */}
            </div>
        </>
    );
};

export default FiltroPropiedades;
import React, { useState,useEffect } from 'react';
import Menu from '../static/menu';
import Linea1 from '../static/linea1';
import Footer from '../static/footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useForm } from 'react-hook-form';
import { CountryDropdown } from 'react-country-region-selector';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import useGeoLocation from "react-ipgeolocation";
import { registroUsuario } from "./../api/connection/auth";
import { Link } from 'react-router-dom';

const Registro = () => {
    const regExp = new RegExp('(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)');
    const emailExp = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [data, setData] = useState({});
    const [country, setCountry] = useState('');
    const [telefono1, setValue] = useState('');
    const [prefijotel1, setpref] = useState('');
    let loc_country = useGeoLocation().country;
    loc_country = loc_country === undefined ? '': loc_country.toLowerCase();
    const showErrors = (origen) => {
        if(origen === "nombre"){
            setError('nombre', { types: { required: "Nombre es Obligatorio", minLength: "Nombre minimo de 3 caracteres"} });
        }
        if(origen === "apellidos"){
            setError('apellidos', { types: { required: "Apellidos son Obligatorios", minLength: "Apellidos minimo de 3 caracteres"} });
        }
        if(origen === "email"){
            setError('email', { types: { required: "Email es Obligatorio", pattern: "Email no válido"} });
        }
        if(origen === "password"){
            setError('password', { types: { required: "La contraseña es obligatoria", minLength: "La contraseña debe de tener mínimo 6 caracteres"} });
        }
        if(origen === "password2"){
            setError('password2', {types: {required: "La contraseña 2 es obligatoria", repeat: "la contraseña no coincide"}});
        }
        if(origen === "telefono"){
            setError('telefono', {types: {required: "Teléfono obligatorio"}});
        }
        if(origen === "fechaNacimiento"){
            setError('fechaNacimiento', {types: {required: "Fecha de nacimiento obligatoria"}});
        }
        if(origen === "nif"){
            setError('nif', {types: {required: "Nif es obiglatorio", pattern: "Nif no válido"}});
        }
        if(origen === "terms"){
            setError('terms', {types: {required: "Debes aceptar nuestros términos y políticas"}});
        }
        if(origen === "all"){
            setError('nombre', { types: { required: "Nombre es Obligatorio", minLength: "Nombre minimo de 3 caracteres"} });
            setError('apellidos', { types: { required: "Apellidos son Obligatorios", minLength: "Apellidos minimo de 3 caracteres"} });
            setError('email', { types: { required: "Email es Obligatorio", pattern: "Email no válido"} });
            setError('password', { types: { required: "La contraseña es obligatoria", minLength: "La contraseña debe de tener mínimo 6 caracteres"} });
            setError('password2', {types: {required: "La contraseña 2 es obligatoria", repeat: "la contraseña no coincide"}});
            setError('telefono', {types: {required: "Teléfono obligatorio"}});
            setError('fechaNacimiento', {types: {required: "Fecha de nacimiento obligatoria"}});
            setError('nif', {types: {required: "Nif es obiglatorio"}});
            setError('terms', {types: {required: "Debes aceptar nuestros términos y políticas"}});
        }
    }
    const handleCountry = (e,s,v) => {
        setCountry(s.target.value)
        setData({...data,[s.target.name]:s.target.value})
        
    }
    const handleSubmitRegister = async (e) => {
        
        e.preventDefault();
        showErrors("all");
        ReactRecaptcha3.getToken().then(async token =>{
            data.token = token;
            await registroUsuario(data).then(res => {
                // console.log(res)
                // alert(`${res.status} - ${res.data.message}`);
            });
         });
        
    }
    const showPhone = (val,coun,ev,form) =>{
        setpref(coun.dialCode);
        setValue(ev.target.value);
        setData({...data,["telefono1"]:ev.target.value,["prefijotel1"]:prefijotel1})
    }
    const handleCheck = (e) => {
        setData({...data,[e.target.name]:e.target.checked});
    }
    const handleChange = (e) => {
        setData({...data,[e.target.name]:e.target.value});
    }
    const changeType = (e) => {
        if(e.target.type === "text"){
            e.target.type = "date";
        }else if(e.target.type === "date"){
            e.target.type = "text";
        }
    }
    useEffect(()=>{
        ReactRecaptcha3.init('6LeaJ_4kAAAAAOur2P6Q1eN3cakeY8_Hyra2wnxk');
    },[]);
    return ( 
        <>
            <Menu />
            <div className="container-firma container-fluid d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        <h1 className="display-4 text-white mb-1 ">Área VIP</h1>
                    </div>
                </div>
            </div>
            <Linea1 />
            <div className="container-xxl pd-5 pdb-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        <h1 className="display-6 mb-2">Ventajas de registrarte</h1>
                        <p>
                        Al registrarte podrás acceder a nuestra red de inmuebles exclusivos antes de su publicación en los diferentes portales inmobiliarios.  
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid pd-5 pdb-5 bg-color-gray">
                <div className="container">
                    <form action='' className='register-form'>
                        <h4>
                            Datos Personales
                        </h4>
                        <div className="row">
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input  type="text" name="nombre" className="form-control" placeholder="Nombre *" onBlur={()=>{showErrors("nombre")}} onChange={(e)=>handleChange(e) } aria-label="Nombre"/>
                                {errors?.nombre && errors?.nombre?.types && (
                                    data?.nombre === undefined || data?.nombre === ''? <p className='text-danger'>{errors?.nombre?.types?.required}</p>:''
                                )}
                                {errors?.nombre && errors?.nombre?.types && (
                                    data?.nombre?.length<3 && data?.nombre?.length>=1?<p className='text-danger'>{errors?.nombre?.types?.minLength}</p>:''
                                )}
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="text" name="apellidos" className="form-control" placeholder="Apellidos  *" onBlur={()=>{showErrors("apellidos")}} onChange={(e)=>handleChange(e)} aria-label="Last name"/>
                                {errors?.apellidos && errors?.apellidos?.types && (
                                    data?.apellidos === undefined? <p className='text-danger'>{errors?.apellidos?.types?.required}</p>:''
                                )}
                                {errors?.apellidos && errors?.apellidos?.types && (
                                    data?.apellidos?.length<3 && data?.apellidos.length>=1?<p className='text-danger'>{errors?.apellidos?.types?.minLength}</p>:''
                                )}
                            
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <PhoneInput country={loc_country} inputProps={{
                                    name: 'telefono',
                                    required: true,
                                }} onChange={(val,coun,ev,form) =>showPhone (val,coun,ev,form)} onBlur={()=>{showErrors("telefono")}} placeholder="Teléfono *" />
                                {errors?.telefono && errors?.telefono?.types && (
                                    data?.telefono1 === undefined || data?.telefono1 === '' ? <p className='text-danger'>{errors?.telefono?.types?.required}</p>:''
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="email" name="email" className="form-control"  placeholder="Email *" onBlur={()=>{showErrors("email")}} onChange={(e)=>handleChange(e)}/>
                                {errors?.email && errors?.email?.types && (
                                    data?.email === undefined? <p className='text-danger'>{errors?.email?.types?.required}</p>:''
                                )}
                                {errors?.email && errors?.email?.types && (
                                    !emailExp.test(data.email)?<p className='text-danger'>{errors?.email?.types?.pattern}</p>:''
                                )}
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input  type="password" name="password" className="form-control" placeholder="Contraseña *"  onBlur={()=>{showErrors("password")}} onChange={(e)=>handleChange(e)}/>
                                {errors?.password && errors?.password?.types && (
                                    data?.password === undefined || data?.password === '' ? <p className='text-danger'>{errors?.password?.types?.required}</p>:''
                                )}
                                {errors?.password && errors?.password?.types && (
                                    data?.password?.length<6 && data?.password.length>=1?<p className='text-danger'>{errors?.password?.types?.minLength}</p>:''
                                )}
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="password" name="password2" className="form-control"  placeholder="Repite Contraseña  *" onChange={(e)=>handleChange(e)} onBlur={()=>{showErrors("password2")}}/>
                                {errors?.password2 && errors?.password2?.types && (
                                    data?.password !== data.password2? <p className='text-danger'>{errors.password2.types.repeat}</p>:''
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="text" name="fechanacimiento" className="form-control" onChange={(e)=>handleChange(e)}
                                    onFocus={(e) =>{changeType(e)}}
                                    onBlur={(e) => {changeType(e); showErrors("fechaNacimiento");}}
                                placeholder="Fecha de nacimiento *"/>
                                {errors?.fechaNacimiento && errors?.fechaNacimiento?.types && (
                                    data?.fechanacimiento === undefined? <p className='text-danger'>{errors?.fechaNacimiento?.types?.required}</p>:''
                                )}
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <CountryDropdown name="pais" defaultOptionLabel="País" placeholder="País " value={country} onChange={(e,s,v) => handleCountry(e,s,v)} className="form-control"/>
                            </div>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="text" name="nacionalidad" className="form-control" placeholder="Nacionalidad " onChange={(e)=>handleChange(e)}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <input type="text" name="nif"className="form-control" placeholder="NIF *" onBlur={()=>{showErrors("nif")}} onChange={(e)=>handleChange(e)} />
                                {errors?.nif && errors?.fechaNacimiento?.types && (
                                    data?.nif === undefined? <p className='text-danger'>{errors?.nif?.types?.required}</p>:''
                                )}
                                {errors?.nif && errors?.nif?.types && (
                                    !regExp.test(data.nif)?<p className='text-danger'>{errors?.nif?.types?.pattern}</p>:''
                                )}
                            </div>
                        </div>
                        <br/>
                        {/* <h4>Datos Opcionales </h4> */}

                        <div className='row'> 
                            {/* <div className="mb-3 col-12">
                                <textarea type="date" className="form-control" placeholder=""/>
                            </div> */}
                            <div className="mb-3 col-lg-4 col-sm-12">
                                <div className="form-check">
                                    <input className="form-check-input-register" type="checkbox" id="inlineFormCheck" name="suscribir" onChange={(e)=>handleCheck(e)}/>
                                    <label className="form-check-label-register" for="inlineFormCheck">Recibir Noticias</label>
                                </div>
                            </div>
                            <div className="mb-3 col-lg-5 col-sm-12">
                                <div className="form-check">
                                    <input className="form-check-input-register" type="checkbox" name="terms" required id="inlineFormCheck" onChange={(e)=>handleCheck(e)} onBlur={()=>setError('terms')}/>
                                    <label className="form-check-label-register" for="inlineFormCheck">Acepto la <Link className='form-label-politic-register' to="/politica-privacidad" target={'_blank'}>política de privacidad</Link> de LAE HOMES</label>
                                </div>
                                {errors?.terms && errors?.terms?.types && (
                                    data?.terms === false ||  data?.terms === undefined? <p className='text-danger'>{errors?.terms?.types?.required}</p>:''
                                )}
                            </div>
                            {/* <div className="mb-3 col">
                                <input type="text" className="form-control" placeholder="NIF" aria-label="Last name"/>
                            </div> */}
                        </div>
                        <br/>
                        <div className="d-flex flex-wrap justify-content-lg-center justify-content-sm-center">
                            {/* <div className="col-sm-12 col-lg-12"> */}
                                <input className='button-register' type="submit" value="REGÍSTRATE" onClick={(e)=>{handleSubmitRegister(e)}} onKeyDown={(e) => e.key === "Enter" ? handleSubmitRegister(e) : ''}/>
                            {/* </div> */}
                        </div>
                    </form>
                   
                </div>
            </div>
            <Footer />
        </> 
    );
}
 
export default Registro;
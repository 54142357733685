import axios from "axios";

const urlPath = "sucursales";

export const listSucursales = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/list`, data.filtros, {
            params: data.params,
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
       
        console.log(error);
    }
}